/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import useWindowSize from '~/hooks/useWindowSize';
import {
  Box,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  Modal,
  Typography,
  Button,
} from '@mui/material';
import {
  CloseRounded,
  RadioButtonUnchecked,
  RadioButtonChecked,
  ExpandMore,
} from '@mui/icons-material';
import useAccountsQuery from '~/hooks/queries/useAccountsQuery';
import useSessionPlQuery from '~/hooks/queries/useSessionPlQuery';
import { useParams } from 'react-router-dom';
import ClientsSelector from '~/components/Clients/ClientsSelector/ClientsSelector';
import SectionTitle from '~/components/Typography/SectionTitle';
import withWindowSize from '~/components/Commons/Responsive/withWindowSize';
import useLoginSeenCookie from '~/hooks/useLoginSeenCookie';
import defaultLogo from '~/assets/img/placeholder.jpg';
import useMenu from '~/context/useMiniMenu';
import { Accounts } from '../ClientsSelector/types';

type AccountsData =
  | {
      accounts: Accounts;
      emptyAccounts: boolean;
    }
  | null
  | undefined;

export const generateFullLogoPath = (relativelogoPath: string | null | undefined) => {
  if (!relativelogoPath) {
    return defaultLogo;
  }
  if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
    return `/preview${relativelogoPath}`;
  }
  return relativelogoPath;
};

function NavbarClientSelector() {
  // * State
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { accountType, accountId } = useParams();
  const buttonRef = useRef<HTMLButtonElement>(null);
  // * Hooks
  const { data: sessionData } = useSessionPlQuery();
  const { data: accountsData } = useAccountsQuery();
  const { isMobile } = useWindowSize();
  const { isOpen } = useMenu();
  const { setLoginSeenCookie } = useLoginSeenCookie();

  // * Functions

  const getBaseLogoPath = (accountData: AccountsData) => {
    if (accountId) {
      if (accountType === 'partner') {
        return accountData?.accounts.partners.find((partner) => {
          return partner.id.toString() === accountId;
        })?.logo;
      }
      return accountData?.accounts.clients
        .find((client) => {
          return client.id.toString() === accountId;
        })
        ?.logo?.replace(/https:\/\/(dev|www)\.dianomi\.com/, '');
    }
    return undefined;
  };

  // * Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };

  // * Variables
  const baseLogoPath = getBaseLogoPath(accountsData);
  const displayLogo = generateFullLogoPath(baseLogoPath);

  const uniqueClient =
    accountsData?.accounts.clientsCount === 1 &&
    accountsData?.accounts.clients.length === 1 &&
    accountsData?.accounts.partnersCount === 0 &&
    accountsData?.accounts.flatAccounts.length === 1;

  const uniquePartner =
    accountsData?.accounts.clientsCount === 0 &&
    accountsData?.accounts.partners.length === 1 &&
    accountsData?.accounts.partnersCount === 1 &&
    accountsData?.accounts.flatAccounts.length === 1;

  const uniqueAccount = uniqueClient || uniquePartner;

  let allowCreateClient;
  if (!sessionData?.isPartner) {
    allowCreateClient = sessionData?.client_permissions.allow_create_client ?? false;
  }

  const emptyAccounts =
    accountsData?.accounts.clientsCount === 0 &&
    accountsData?.accounts.partnersCount === 0 &&
    accountsData?.accounts.flatAccounts.length === 0;

  let label = '';

  if (emptyAccounts) {
    label = 'No accounts available';
  } else if (sessionData?.isPartner) {
    if (sessionData?.client.has_active_child > 0) {
      label = 'Publisher Parent Account';
    } else {
      label = 'Publisher';
    }
  } else if (sessionData && sessionData?.client.has_active_child > 0) {
    label = 'Advertiser Parent Account';
  } else {
    label = 'Advertiser';
  }
  let isSingleTabMenu: boolean | undefined;
  if (accountsData) {
    const clients = accountsData.accounts.clients.length;
    const partners = accountsData.accounts.partners.length;
    isSingleTabMenu = (clients === 0 || partners === 0) && clients + partners > 0;
  }

  return (
    <div style={{ flex: 1 }}>
      {(uniqueAccount || emptyAccounts) && !allowCreateClient ? (
        <>
          {uniqueAccount && (
            <Typography
              sx={(theme) => ({
                display: 'block',
                color: theme.palette.grey[500],
                paddingLeft: '10px',
                fontSize: '15px',
              })}
            >
              {label}
            </Typography>
          )}
          <Button
            href="#"
            sx={{
              display: 'flex',
              justifyContent: 'start',
              overflow: 'hidden',
              lineHeight: '35px',
              fontSize: isMobile ? '20px' : '27px',
              fontWeight: '600',
              borderRadius: '3px',
              textTransform: 'none',
              width: isMobile ? '40vw' : '50vw',
              minWidth: '300px',
              color: 'secondary.main',
              padding: isMobile ? '10px' : '0px 0px 2px',
              marginLeft: '10px',
              whiteSpace: 'break-spaces',
              cursor: 'default',
              ':hover': {
                color: 'secondary.main',
                cursor: 'default',
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <img
              src={displayLogo}
              style={{ margin: '2px 10px 0 0', maxWidth: '28px', maxHeight: '28px' }}
              alt={sessionData?.name}
            />
            {sessionData?.name}
          </Button>
        </>
      ) : (
        <>
          {sessionData ? (
            <Typography
              sx={(theme) => ({
                display: 'block',
                color: theme.palette.grey[500],
                paddingLeft: '10px',
                fontSize: '15px',
              })}
            >
              {label}
            </Typography>
          ) : null}
          <Button
            sx={{
              display: 'flex',
              justifyContent: 'start',
              overflow: 'hidden',
              lineHeight: '35px',
              fontSize: isMobile ? '20px' : '27px',
              fontWeight: '600',
              borderRadius: '3px',
              textTransform: 'none',
              width: isMobile ? '40vw' : '50vw',
              minWidth: '300px',
              color: 'secondary.main',
              padding: isMobile ? '10px' : '0px 0px 2px',
              marginLeft: '10px',
              whiteSpace: 'break-spaces',
            }}
            onClick={handleClick}
            ref={buttonRef}
          >
            <img
              src={displayLogo}
              style={{ margin: '2px 10px 0 0', maxWidth: '28px', maxHeight: '28px' }}
              alt={sessionData?.name}
            />
            {sessionData && sessionData.name ? (
              <Box
                sx={{
                  fontSize: isMobile ? '16px' : '26px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                }}
              >
                {sessionData.name}
              </Box>
            ) : (
              <Box>Select a client</Box>
            )}
            <Typography
              sx={{
                fontWeight: '100',
                paddingLeft: '15px',
                fontSize: '15px',
              }}
            >
              Change
            </Typography>
            {isMobile ? (
              <ExpandMore
                sx={{
                  transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
                  transition: 'transform 0.3s ease-in-out',
                }}
              />
            ) : open ? (
              <RadioButtonChecked
                sx={{
                  paddingLeft: '5px',
                  transform: open ? ' scale(1.2)' : 'scale(1)',
                }}
              />
            ) : (
              <RadioButtonUnchecked
                sx={{
                  paddingLeft: '5px',
                  transform: open ? ' scale(1.2)' : 'scale(1)',
                  ':hover': {
                    transform: 'scale(1.1)',
                  },
                }}
              />
            )}
          </Button>
          {!isMobile && open ? (
            <ClickAwayListener onClickAway={handleClose}>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-menu"
                aria-describedby="modal-modal-menu"
                sx={{ zIndex: '2100' }}
                data-testid="navbar-modal"
                slotProps={{
                  backdrop: {
                    sx: {
                      backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    },
                  },
                }}
              >
                <Box
                  sx={(theme) => ({
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -40%)',
                    [theme.breakpoints.up('lg')]: {
                      transform: 'translate(-50%, -50%)',
                    },
                  })}
                >
                  <Paper
                    sx={(theme) => ({
                      minWidth: isSingleTabMenu ? '40vw' : '70vw',
                      borderRadius: '5px',
                      padding: '10px',
                      [theme.breakpoints.up('lg')]: {
                        minWidth: !isSingleTabMenu && '60vw',
                      },
                    })}
                  >
                    <SectionTitle
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                        marginBottom: '0px',
                        width: '100%',
                        fontSize: isMobile ? '18px' : '28px',
                      }}
                    >
                      Select an account:
                      <Button
                        onClick={handleClick}
                        sx={{
                          position: 'absolute',
                          right: isSingleTabMenu ? '0px' : '10px',
                          top: isSingleTabMenu ? '0px' : '10px',
                          borderRadius: '50%',
                          height: '30px',
                          width: '30px',
                          minWidth: 0,
                        }}
                      >
                        <CloseRounded
                          sx={{
                            fontSize: isSingleTabMenu ? '1em' : '2em',
                            transition: 'transform 0.2s ease-in-out',
                            transform: 'rotate(0deg)',
                            '&:hover': {
                              transform: 'rotate(90deg)',
                            },
                          }}
                        />
                      </Button>
                    </SectionTitle>
                    <ClientsSelector
                      onDoneClick={() => {
                        setOpen(false);
                        setLoginSeenCookie();
                      }}
                      accounts={accountsData?.accounts}
                    />
                  </Paper>
                </Box>
              </Modal>
            </ClickAwayListener>
          ) : (
            <ClickAwayListener onClickAway={handleClose}>
              <Popper
                id="client-selector"
                open={open && isOpen}
                transition
                anchorEl={anchorEl}
                placement="bottom-start"
                data-testid="navbar-modal"
                sx={{
                  zIndex: 2100,
                  width: '100%',
                }}
              >
                {({ TransitionProps }) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Grow {...TransitionProps} id="menu-list" style={{ transformOrigin: '0 0 0' }}>
                    <Paper>
                      <ClientsSelector
                        onDoneClick={() => {
                          setOpen(false);
                          setLoginSeenCookie();
                        }}
                        accounts={accountsData?.accounts}
                      />
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </ClickAwayListener>
          )}
        </>
      )}
    </div>
  );
}

export default withWindowSize(NavbarClientSelector);
