import React from 'react';
import { withStyles } from '@mui/styles';
import TabBase from '@mui/material/Tab';

const styles = (theme) => ({
  root: {
    textTransform: 'initial',
    minWidth: 0,
    minHeight: 0,
    fontSize: '14px',
    fontWeight: 400,
    padding: '10px 16px',
    color: theme.palette?.text.primary,
    backgroundColor: theme.palette?.secondary.main,
    flexGrow: 1,
    '&:hover': {
      color: theme.palette?.primary.light,
      fontWeight: 'bold',
      opacity: 1,
    },
    '&.tabSelected': {
      color: theme.palette?.secondary.main,
      fontWeight: 400,
    },
    '&:focus': {
      color: theme.palette?.primary.light,
    },
  },
  selected: {
    fontWeight: 'bold !important',
  },
});

function Tab({ classes, label, ...props }) {
  return (
    <TabBase
      classes={{ root: classes.root, selected: classes.selected }}
      label={label}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

Tab.propTypes = TabBase.propTypes;

export default withStyles(styles)(Tab);
