/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, BoxProps } from '@mui/material';
import styles from './styles';

type TitleProps = {
  children: React.ReactNode;
  className?: string;
} & BoxProps;

export function Title({ children, className = '', ...props }: TitleProps) {
  return (
    <Box component="h3" sx={styles.pageTitle} className={className} {...props}>
      {children}
    </Box>
  );
}

/**
 * The `<SectionTitle />` component is to be used as a section title to differentiate between areas of a page.
 * This component is not to be used in the header, as the styling here differs.
 * Use case example: We have two tables on a single page where we want to use titles to indicate each tables purpose.
 * @example
 *  <SectionTitle>
      {prop.title}
    </SectionTitle>
 */

export default Title;
