import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import usePrevious from '~/hooks/usePrevious';
import GridContainer from '~/components/Commons/Grid/GridContainer';
import GridItem from '~/components/Commons/Grid/GridItem';
import errorPageStyles from '~/views/Pages/errorPageStyles';
import Button from '~/components/Commons/Button/index';
import { makeStyles } from '@mui/styles';
import CustomLink from '~/components/Commons/Link/Link';

type Props = {
  resetError?: () => void;
  error?: unknown;
};

const useStyles = makeStyles(errorPageStyles);

function ErrorPage({ resetError = undefined, error = undefined }: Props) {
  const location = useLocation();
  const classes = useStyles();
  const previousPathname = usePrevious(location.pathname);
  useEffect(() => {
    if (previousPathname && previousPathname !== location.pathname) {
      resetError?.();
    }
  }, [resetError, previousPathname, location.pathname]);

  let is500Error = false;
  if (
    error &&
    typeof error === 'object' &&
    'response' in error &&
    error.response instanceof Response
  ) {
    is500Error = error?.response?.status >= 500;
  }

  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          {is500Error ? (
            <>
              <h1 className={classes.title}>500</h1>
              <h2 className={classes.subTitle}>
                It looks like we&apos;re having technical difficulties
              </h2>
              <h3 className={classes.description}>
                We&apos;re working to fix the problem as quickly as possible. Please try again
                later.
              </h3>
            </>
          ) : (
            <>
              <h1 className={classes.title}>500</h1>
              <h2 className={classes.subTitle}>Oooops, there was an error!</h2>
              <h3 className={classes.description}>
                This has been reported to the administrator and we will fix it ASAP
              </h3>
              <CustomLink to="/">
                <Button color="primary" onClick={() => null}>
                  Back to home page
                </Button>
              </CustomLink>
            </>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default ErrorPage;
