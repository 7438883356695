/* eslint-disable react/prop-types */
import useAccountsQuery from '~/hooks/queries/useAccountsQuery';
import useClientPlQuery from '~/hooks/queries/useClientPlQuery';
import useGenieNavQuery from '~/hooks/queries/useGenieNavQuery';
import useSessionPlQuery from '~/hooks/queries/useSessionPlQuery';
import * as React from 'react';
import ClientInfo from '.';

export const ClientInfoIsLoading = React.createContext();

export const ClientInfoProvider = ({ children }) => {
  const { data: sessionInfo, isInitialLoading: isLoadingSession } = useSessionPlQuery();

  const { data: accountsData, isInitialLoading: isLoadingAccounts } = useAccountsQuery();
  const { data: genieNavStats, isInitialLoading: isLoadingGenieNav } = useGenieNavQuery();
  const { data: clientData, isInitialLoading: isLoadingClientPl } = useClientPlQuery();
  const clientInfo = React.useMemo(() => {
    const defaultClientInfo = {
      name: '',
      logo: null,
      isPartner: false,
      accounts: { clients: [], partners: [] },
    };

    let obj;
    if (sessionInfo) {
      obj = {
        ...defaultClientInfo,
        ...accountsData,
        ...sessionInfo,
        ...clientData,
        nav: {
          ...sessionInfo.nav,
          analytics: genieNavStats,
        },
      };
    } else {
      obj = null;
    }
    return obj;
  }, [accountsData, sessionInfo, clientData, genieNavStats]);

  const isLoading = isLoadingSession || isLoadingAccounts || isLoadingGenieNav || isLoadingClientPl;
  return (
    <ClientInfo.Provider value={clientInfo}>
      <ClientInfoIsLoading.Provider value={isLoading}>{children}</ClientInfoIsLoading.Provider>
    </ClientInfo.Provider>
  );
};

/**
 *
 * @deprecated Use the `use****Query()`s instead, see src/context/ClientInfo/useClientInfo.jsx
 *
 */

const useClientInfo = () => {
  const clientInfo = React.useContext(ClientInfo);
  if (clientInfo === undefined) {
    throw new Error('useClientInfo must be used within a ClientInfoProvider');
  }
  return clientInfo;
};

export default useClientInfo;

export const useClientInfoIsLoading = () => {
  const isLoading = React.useContext(ClientInfoIsLoading);

  if (isLoading === undefined) {
    throw new Error('useClientInfoIsLoading must be used within a ClientInfoIsLoadingProvider');
  }
  return isLoading;
};
