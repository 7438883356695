import { Theme } from '@mui/material';

const headerStyle = (theme: Theme) =>
  ({
    appBar: {
      backgroundColor: theme.palette?.primary.main,
      boxShadow: 'none',
      borderBottom: '0',
      marginBottom: '0',
      position: 'relative',
      width: '100%',
      zIndex: '2500',
      border: '0',
      transition: 'all 150ms ease 0s',
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      minHeight: '70px',
      [theme?.breakpoints?.up('sm')]: {
        minHeight: '86px',
      },
    },
    appBarMobile: {
      height: '100%',
      maxHeight: '51px',
      position: 'relative',
    },
    appDrawer: {
      color: theme.palette?.secondary.main,
      '&:hover': {
        color: theme.palette?.primary.light,
      },
      width: '50px',
    },
    appDrawerMobile: {
      color: theme.palette?.secondary.main,
      '&:hover': {
        color: theme.palette?.primary.light,
      },
    },
    appTitleMobile: {
      color: theme.palette?.secondary.main,
      fontSize: '1.2em',
      fontWeight: '600',
      lineHeight: '1em',
      textAlign: 'center',
    },
    container: {
      minHeight: '0',
      padding: '0 10px',
      width: '100%',
    },
    sidebarMinimize: {
      float: 'left',
      padding: '0 0 0 15px',
      display: 'block',
      color: theme.palette?.grey[800],
    },
    sidebarMiniIcon: {
      width: '20px',
      height: '17px',
    },
    logo: {
      margin: '0',
      display: 'flex',
      position: 'relative',
      zIndex: '4',
      backgroundColor: theme.palette?.primary.main,
      width: '245px',
      borderRight: '1px solid #C4D0DD',
    },
    logoMini: {
      transition: 'all 300ms linear',
      opacity: 1,
      float: 'left',
      textAlign: 'center',
      width: '30px',
      display: 'inline-block',
      maxHeight: '30px',
      marginLeft: '22px',
      marginRight: '18px',
      marginTop: '7px',
      color: 'inherit',
    },
    logoNormal: {
      transition: 'all 300ms linear',
      display: 'block',
      opacity: '1',
      transform: 'translate3d(0px, 0, 0)',
      textTransform: 'uppercase',
      fontSize: '18px',
      whiteSpace: 'nowrap',
      fontWeight: '400',
      lineHeight: '30px',
      overflow: 'hidden',
      '&,&:hover,&:focus': {
        color: 'inherit',
      },
    },
    logoNormalSidebarMini: {
      opacity: '0',
      transform: 'translate3d(-25px, 0, 0)',
    },
    img: {
      width: '180px',
      verticalAlign: 'middle',
      border: '0',
      position: 'relative',
    },
  }) as const;

export default headerStyle;
