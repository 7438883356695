import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Snack from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';

import snackbarContentStyle from '~/components/Commons/Snackbar/snackbarContentStyle';

function Snackbar({ ...props }) {
  const { classes, message, color, close, place, open } = props;
  let action = [];
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => props.closeNotification()}
        size="large"
      >
        <Close className={classes.close} />
      </IconButton>,
    ];
  }
  const verticalPosition = place.indexOf('t') === -1 ? 'bottom' : 'top';
  let horizontalPosition = 'right';
  if (place.indexOf('l') !== -1) {
    horizontalPosition = 'left';
  } else if (place.indexOf('c') !== -1) {
    horizontalPosition = 'center';
  }

  return (
    <Snack
      classes={{
        anchorOriginTopCenter: classes.top20,
        anchorOriginTopRight: classes.top40,
        anchorOriginTopLeft: classes.top40,
      }}
      anchorOrigin={{
        vertical: verticalPosition,
        horizontal: horizontalPosition,
      }}
      open={open}
      message={message}
      action={action}
      ContentProps={{
        classes: {
          root: `${classes.root} ${classes[color]}`,
          message: classes.message,
        },
      }}
    />
  );
}

Snackbar.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'primary', 'white']).isRequired,
  close: PropTypes.bool.isRequired,
  place: PropTypes.oneOf(['tl', 'tr', 'tc', 'br', 'bl', 'bc']).isRequired,
  open: PropTypes.bool.isRequired,
  closeNotification: PropTypes.func.isRequired,
};

export default withStyles(snackbarContentStyle)(Snackbar);
