import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  pageHeaderTitle: {
    fontWeight: '600',
    color: theme.palette?.primary.main,
    margin: 0,
    padding: '0px 5px',
    width: '100%',
  },
});

function PageHeaderTitle({ classes, children }) {
  return <h1 className={classes.pageHeaderTitle}>{children}</h1>;
}

PageHeaderTitle.propTypes = {
  classes: PropTypes.instanceOf(Object),
  children: PropTypes.node,
};
PageHeaderTitle.defaultProps = {
  classes: null,
  children: null,
};

export default withStyles(styles)(PageHeaderTitle);
