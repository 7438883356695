/* eslint-disable @typescript-eslint/no-explicit-any */
export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export type TruthyNumber = 0 | 1;

type SnakeToCamel<str> = str extends `${infer first}_${infer rest}`
  ? `${first}${SnakeToCamel<Capitalize<rest>>}`
  : str;

export type CamelCaseObj<T> = T extends { [k: string]: any }
  ? { [K in keyof T as SnakeToCamel<Extract<K, string>>]: CamelCaseObj<T[K]> }
  : T;

/**
 * Converts an object with snake_case keys to camelCase
 * There are some edge cases it doesn't handle well and both me and chatgpt
 * can't figure out how to cover those cases. e.g.
 * 'key_' -> 'key_' instead of 'key'
 * or '_key' -> '_key'
 * or '_key_test' -> 'KeyTest'
 * basically anything with leading or trailing underscores
 * seeing as that isn't snake_case anyway i'm not too worried, but it would be nice to fix
 */
export function camelizeSnakeCaseKeys<T extends { [key: string]: any }>(obj: T): CamelCaseObj<T> {
  const newObj = {} as CamelCaseObj<T>;

  for (const [key, value] of Object.entries(obj)) {
    const camelizedKey = key.replace(/_[a-zA-Z]/g, (match) =>
      match[1].toUpperCase(),
    ) as SnakeToCamel<typeof key>;
    const newValue = value && typeof value === 'object' ? camelizeSnakeCaseKeys(value) : value;

    newObj[camelizedKey as keyof CamelCaseObj<T>] = newValue;
  }

  return newObj;
}

type UppercaseLetter =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z';

type SnakeCase<str, output extends string = ''> = str extends `${infer first}${infer rest}`
  ? first extends UppercaseLetter
    ? output extends ''
      ? SnakeCase<rest, Lowercase<first>>
      : SnakeCase<rest, `${output}_${Lowercase<first>}`>
    : SnakeCase<rest, `${output}${first}`>
  : output extends ''
    ? str
    : output;

export type SnakeObj<T> = {
  [K in keyof T as SnakeCase<string & K>]: T[K] extends Record<string, any> ? SnakeObj<T[K]> : T[K];
};

/**
 * Converts an object with camelCase keys to snake_case
 * There are some edge cases it doesn't handle well and both me and chatgpt
 * can't figure out how to cover those cases. e.g.
 * 'last Name' -> 'last _name'. seeing as this isn't camelCase anyway i'm not too worried,
 * but it would be nice to fix _at some point_
 */
export function convertKeysCamelToSnake<T extends Record<string, any>>(obj: T) {
  const newObj = {} as Prettify<SnakeObj<T>>;

  for (const [key, value] of Object.entries(obj)) {
    const snakeKey = key.replace(
      /[A-Z]/g,
      (match) => `_${match.toLowerCase()}`,
    ) as keyof SnakeObj<T>;
    const newValue = typeof value === 'object' ? convertKeysCamelToSnake(value) : value;

    newObj[snakeKey] = newValue;
  }

  return newObj;
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

/**
 * This is technically unsafe, but it's a lot easier to use than Object.entries
 *
 *
 * **BE WARNED ONLY USE IT IF YOU _ABSOLUTELY_ KNOW WHAT THE OBJECT YOU'RE PASSING CONTAINS**
 */
export function entries<t extends Record<string, any>>(o: t): Entries<t> {
  return Object.entries(o) as any;
}

export type ErrorObjectString = {
  error: string;
};

export type UniqueArrayOfUnion<TUnion, TArray extends TUnion[]> = Exclude<
  TUnion,
  TArray[number]
> extends never
  ? [TUnion]
  : [...TArray, Exclude<TUnion, TArray[number]>];
