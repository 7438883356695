import React from 'react';
import theme from '~/theme';
import classnames from 'classnames';
import Button, { ButtonProps } from '@mui/material/Button';
import { FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styles from './styles';

const useStyles = makeStyles(styles);

interface Props extends ButtonProps {
  error?: boolean;
  errorMessage?: string | null;
  disabled?: boolean;
  className?: string;
  name?: string;
  wrap?: boolean;
  isValid?: boolean;
  color?: 'primary' | 'secondary';
}

const StyledButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      style,
      children,
      onClick,
      error = false,
      errorMessage = undefined,
      color = undefined,
      disabled = false,
      isValid = undefined,
      className = '',
      name,
      wrap,
      ...rest
    }: Props,
    ref,
  ) => {
    const classes = useStyles();
    const btnClasses = classnames(
      {
        [classes.button]: true,
        [className]: className,
        [classes.wrap]: wrap,
        [classes.isValid]: isValid,
      },
      color ? classes[color] : '',
    );
    return (
      <>
        <Button
          className={btnClasses}
          onClick={onClick}
          style={style}
          disabled={disabled}
          name={name}
          ref={ref}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          aria-describedby={`${name}-helper-text`}
        >
          {children}
        </Button>
        <FormHelperText
          sx={error ? { ...theme.defaultStyles.errorContainer } : { display: 'block' }}
          error={error}
          id={`${name}-helper-text`}
        >
          {errorMessage}
        </FormHelperText>
      </>
    );
  },
);

export default StyledButton;
