/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/react';
import { customFetch } from '~/utils/customFetch';

import dayjs from 'dayjs';
import { perms } from '~/routes';

const getYearWeek = (date) => {
  let dayjsDate = dayjs(date).hour(0).minute(0).second(0).millisecond(0);

  // workaround for start of year and week not matching
  if (dayjsDate.isSame(dayjs(date).startOf('year'))) {
    dayjsDate = dayjs(date).date(4);
  }
  // Thursday in current week decides the year, thursday is 4th day of week.
  const thursDate = dayjs(dayjsDate).day(4);
  const year = thursDate.format('YYYY');

  // January 4 is always in week 1.
  const thursWeekOneDate = dayjs(thursDate).month(0).date(4);

  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  const weekNumber = dayjsDate.diff(thursWeekOneDate, 'week');

  // API needs two digit week number
  if (weekNumber.toString().length === 1) {
    return `${year}0${weekNumber}`;
  }

  return `${year}${weekNumber}`;
};

const formatByPeriod = (date, period) => {
  if (period === 'yearmonth') {
    return dayjs(date).format('YYYYMM');
  }
  if (period === 'year') {
    return dayjs(date).format('YYYY');
  }
  if (period === 'yearweek') {
    return getYearWeek(date);
  }
  return dayjs(date).format('YYYYMMDD');
};

/**
 * @typedef {Object.<string, any>} Daum
 * 
 * 
 * @typedef {{
 *   hidden_columns: string
  field_names: string[]
  total_columns: string
  chart_columns: string
  data: Daum[]
  field_display_names: string[]
  type: any
  title: string
 * }} Response
 *
 * @param {string|number|undefined} clientId
 * @param {boolean|undefined} isPartner
 * @param {string} from
 * @param {string} to
 * @param {string} filter
 * @param {string[]} productIds
 * @param {string} linkId
 * @param {string} period
 * @param {boolean} forCSV
 * @returns {Promise<{data:Response}>}
 */
async function analyticsData(
  clientId,
  isPartner,
  from,
  to,
  filter,
  productIds,
  linkId = 'undefined',
  period = '',
  forCSV = false,
) {
  // Multiple campaigns selected
  const formattedFrom = formatByPeriod(from, period);

  const formattedTo = formatByPeriod(to, period);

  const copyProductIds = productIds.filter(
    (productId) => productId === 'all' || !Number.isNaN(Number(productId)),
  );
  const requestOptions = {
    params: {
      from: formattedFrom,
      to: formattedTo,
      v: filter,
      products: copyProductIds.includes('all') ? '' : copyProductIds.join(','),
      linkID: linkId,
      period,
      no_html: 0,
    },
    headers: {
      ...(forCSV ? { accept: 'text/csv' } : {}),
    },
    shouldUseBasePath: true,
  };
  if (isPartner) {
    requestOptions.params.partner_id = clientId;
  } else {
    requestOptions.params.client_id = clientId;
  }
  if (forCSV) {
    requestOptions.params.format = 'csv';
  }
  return customFetch.get(`/geneus.pl`, requestOptions).catch((error) => {
    Sentry.withScope((scope) => {
      scope.setTags({
        message: 'Error trying to call geneus.pl',
        file: 'DataService.jsx',
        fn: 'analyticsData()-01',
      });
    });
    console.error(error);
    return false;
  });
}

// Build navigation from Analytics session.pl endpoint
function analyticsNav(analytics) {
  let links = [];
  if (!analytics) {
    return links;
  }
  const newViews = Object.values(analytics).map((stat) => {
    if (stat?.links) {
      links = [
        ...links,
        ...Object.entries(stat.links).map(([shortcode, link]) => ({
          name: `${stat.name} ${link}`,
          hidden: true,
          path: `/analytics/${shortcode}/:linkId`,
          linkFrom: stat.name,
          layout: '/admin',
          perm: stat.name,
          live: true,
          paramsToKeep: ['from', 'to', 'period', 'campaign'],
        })),
      ];
    }
    return {
      hidden: stat.linked,
      path: `/analytics/${stat.shortcode}`,
      name: stat.name,
      layout: '/admin',
      perm: stat.name,
      live: true,
      paramsToKeep: ['from', 'to', 'period', 'campaign'],
    };
  });
  return [...newViews, ...links].sort((a, b) => (a.name > b.name ? 1 : -1));
}

function insightsNav(insightRoutes) {
  const nav = insightRoutes
    .map((insight) => ({
      path: `/insights/${insight.path}`,
      name: insight.name,
      componentName: 'Insights',
      layout: '/admin',
      perm: perms.insights,
      live: true,
      paramsToKeep: ['timeframe', 'country'],
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  return nav;
}

/**
 * @param {string|number|undefined} clientId,
 * @param {boolean|undefined} isPartner,
 * @param {number} timeframe,
 * @param {number|undefined} group,
 * @param {number} country,
 * @returns {Promise<{rows: [], cols: []}>}
 */
function insightsData(clientId, isPartner, timeframe, group, country) {
  const requestOptions = {
    params: {
      timeframe,
      id: group,
      country_id: country,
    },
  };
  if (isPartner) {
    requestOptions.params.partner_id = clientId;
  } else {
    requestOptions.params.client_id = clientId;
  }

  return customFetch
    .get(`/stat.pl`, requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET stat.pl',
            file: 'DataService.jsx',
            fn: 'insightsData()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET stat.pl',
          file: 'DataService.jsx',
          fn: 'insightsData()-02',
        });
        Sentry.captureException(error);
      });
      return [];
    });
}

function splashData({ from = '', to = '', splashType }) {
  // splash.pl requires dates in the format yyyy-mm-dd
  const formatDate = (date) => {
    let formattedDate = date;
    if (formattedDate === '') {
      formattedDate = new Date().toISOString().slice(0, 10);
    }
    return formattedDate.length === 8
      ? `${formattedDate.substring(0, 4)}-${formattedDate.substring(
          4,
          6,
        )}-${formattedDate.substring(6)}`
      : formattedDate;
  };

  const requestOptions = {
    params: {
      type: splashType || 'standard',
      date_from: formatDate(from),
      date_to: formatDate(to),
    },
  };

  return customFetch
    .get(`/splash.pl`, requestOptions)
    .then(
      (result) => (result.data ? result.data : { error: 'No data was returned' }),
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET splash.pl',
            file: 'DataService.jsx',
            fn: 'splashData()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET splash.pl',
          file: 'DataService.jsx',
          fn: 'splashData()-02',
        });
        Sentry.captureException(error);
      });
      return [];
    });
}
/**
 * @typedef {{
 *  id: number
 *  label: string
 *  thumbnail: string
 *  value: string
 * }} StockImage
 *
 * @returns {Promise<StockImage[] | {error: string}>}
 */
async function getStockImages() {
  const result = await customFetch.get(`/stock_image.pl`);
  return result.data;
}

/**
 * @typedef {{
 *  id: number
 * thumbnail: string
 * value: string
 * }} LibraryImage
 * @returns {Promise<LibraryImage[] | {error: string}>}
 */
async function getLibraryImages() {
  const result = await customFetch.get(`/image_library.pl`);
  return result.data;
}

function uploadImage(image) {
  if (image === '') {
    return Promise.resolve('');
  }
  const data = new FormData();
  data.append('file', image);
  return customFetch
    .post(`/jquery_upload2.pl`, data)
    .then((res) => {
      let logoPath = '';
      if (res.data && res.data.files && res.data.files[0]) {
        logoPath = res.data.files[0].url;
      }
      return logoPath;
    })
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to POST to jquery_upload2.pl',
          file: 'DataService.jsx',
          fn: 'uploadImage()-02',
        });
        Sentry.captureException(error);
      });
      return '';
    });
}
/**
 * @param {File} image
 * @param {number | undefined | null} width
 * @param {number | undefined | null} height
 * @param {string | undefined} inviteId
 * @returns {Promise<string | {error: string}>}
 */
function processImage(image, width = null, height = null, inviteId = '') {
  // ! Future handling for webp images
  const formData = new FormData();
  formData.append('image', image);
  formData.append('width', width.toString());
  formData.append('height', height.toString());
  if (inviteId) {
    formData.append('invite_id', inviteId);
  }
  return customFetch
    .post('/darkroom_upload.pl', formData)
    .then(
      (res) => {
        if (res.data && res.data.error) {
          return { error: res.data.error };
        }
        let logoPath = '';
        if (res.data && res.data.files && res.data.files[0]) {
          logoPath = res.data.files[0].url;
        }
        return logoPath;
      },
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to POST to darkroom_upload.pl',
            file: 'DataService.jsx',
            fn: 'processImage()-01',
          });
          Sentry.captureException(error);
        });
        return { error: 'Error uploading image' };
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to POST to darkroom_upload.pl',
          file: 'DataService.jsx',
          fn: 'processImage()-02',
        });
        Sentry.captureException(error);
      });
      return { error: 'Error uploading image' };
    });
}

function pubDashboardHeaderData(partnerId) {
  const requestOptions = {
    partner_id: partnerId,
  };

  return customFetch.get(`/publisher_dashboard/header.pl`, requestOptions).then((res) => res.data);
}

function pubDashboardDailyImpressionData({ partnerId, from, to, byPlatform, byDate, adType }) {
  if (!['all', 'native', 'video'].includes(adType)) {
    throw new Error(`Invalid adType: ${adType}`);
  }
  const requestOptions = {
    params: {
      partner_id: partnerId,
      from,
      to,
      by_platform: byPlatform ? 1 : 0,
      by_date: byDate ? 1 : 0,
      ad_type: adType,
    },
  };

  return customFetch
    .get(`/publisher_dashboard/daily_impressions.pl`, requestOptions)
    .then((res) => {
      return res.data;
    });
}

function pubDashboardDailyVideoStats(partnerId, from, to) {
  const requestOptions = {
    params: {
      partner_id: partnerId,
      from,
      to,
    },
  };

  return customFetch.get(`/publisher_dashboard/video_data.pl`, requestOptions).then((res) => {
    return res.data;
  });
}

const countries = [
  {
    label: 'Australia',
    value: 9,
  },
  {
    label: 'Canada',
    value: 31,
  },
  {
    label: 'Germany',
    value: 64,
  },
  {
    label: 'Hong Kong',
    value: 250,
  },
  {
    label: 'India',
    value: 76,
  },
  {
    label: 'Singapore',
    value: 155,
  },
  {
    label: 'United Kingdom',
    value: 183,
  },
  {
    label: 'United States',
    value: 184,
  },
];

export const DataService = {
  analyticsData,
  analyticsNav,
  splashData,
  insightsNav,
  insightsData,
  pubDashboardHeaderData,
  pubDashboardDailyImpressionData,
  pubDashboardDailyVideoStats,
  getStockImages,
  getLibraryImages,
  uploadImage,
  processImage,
  countries,
};
