import React from 'react';
import GridContainer from '~/components/Commons/Grid/GridContainer';
import GridItem from '~/components/Commons/Grid/GridItem';

import errorPageStyles from '~/views/Pages/errorPageStyles';
import { makeStyles } from '@mui/styles';
import CustomLink from '~/components/Commons/Link/Link';

const useStyles = makeStyles(errorPageStyles);

function ErrorPage() {
  const classes = useStyles();
  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>404</h1>
          <h2 className={classes.subTitle}>Page not found :(</h2>
          <h4 className={classes.description}>Ooooups! Looks like you got lost.</h4>
          <CustomLink to="/">Click here to go home</CustomLink>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default ErrorPage;
