import { hexToRgb } from '~/theme';

const SidebarLinkStyles = (theme) => ({
  collapseItemText: {
    margin: '0',
    position: 'relative',
    transform: 'translateX(0px)',
    opacity: '1',
    whiteSpace: 'normal',
    display: 'block',
    transition: 'all 300ms ease 0s',
    fontSize: '14px',
    fontWeight: 600,
    color: 'inherit',
    padding: '10px 10px 10px 40px',
    borderBottom: `solid 1px ${theme.palette?.primary.main}`,
    '&:focus, &:hover': {
      borderBottomColor: `${theme.palette?.primary.main} !important`,
    },
    lineHeight: '20px',
    maxWidth: theme?.defaultStyles?.drawerWidth,
  },
  collapseItemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
  },
  collapseItemTextActive: {
    borderBottomColor: `${theme.palette?.primary.main} !important`,
  },
  collapseItem: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '10px 0 0 0',
    padding: '0',
  },
  collapseItemLink: {
    transition: 'all 300ms linear',
    margin: '0',
    position: 'relative',
    display: 'block',
    padding: '0px 20px',
    backgroundColor: 'transparent',
    color: theme.palette?.secondary.main,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: `rgba(${hexToRgb(theme.palette?.grey[500])}, 0.2)`,
      boxShadow: 'none',
    },
    '&:hover,&.active': {
      outline: 'none',
      backgroundColor: theme.palette?.secondary.main,
      color: theme.palette?.primary.light,
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {},
  },
  caret: {
    marginTop: '13px',
    position: 'absolute',
    left: '-20px',
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
  },
  'caret-level-0': {
    left: 'auto',
    right: '18px',
  },
  'caret-level-1': {
    left: 'auto',
    right: '18px',
  },
  caretActive: {
    transform: 'rotate(180deg)',
  },
  item: {
    color: 'inherit',
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    padding: '0',
  },
  itemLink: {
    transition: 'all 300ms linear',
    margin: '0',
    position: 'relative',
    display: 'block',
    padding: '20px',
    backgroundColor: 'transparent',
    color: theme.palette?.secondary.main,
    width: 'auto',
    borderRadius: 0,
    cursor: 'pointer',
    '&:hover,&.active': {
      outline: 'none',
      backgroundColor: theme.palette?.secondary.main,
      color: theme.palette?.primary.light,
      boxShadow: 'none',
    },
    '&:focus': {
      textDecoration: 'underline',
    },
    paddingBottom: '0',
    [theme.breakpoints?.down('md')]: {
      paddingBottom: '20px',
    },
  },
  itemText: {
    margin: '0',
    lineHeight: '30px',
    fontSize: '16px',
    transform: 'translate3d(0px, 0, 0)',
    opacity: '1',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap',
    fontWeight: '600',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette?.secondary.main,
    paddingBottom: '20px',
    color: 'inherit',
    '&:focus, &:hover': {
      borderBottomColor: `${theme.palette?.primary.main} !important`,
    },
    [theme.breakpoints?.down('md')]: {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },

  itemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
  },
  listItem: {
    display: 'block',
    padding: '0px',
  },
  itemIcon: {
    color: 'inherit',
    width: '30px',
    float: 'left',
    position: 'inherit',
    marginRight: '15px',
    marginLeft: '5px',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
});

export default SidebarLinkStyles;
