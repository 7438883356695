import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { withStyles } from '@mui/styles';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Button from '~/components/Commons/Button';
import Snackbar from '~/components/Commons/Snackbar/Snackbar';

import notificationsStyle from './notificationsStyle';

function Transition(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="down" {...props} />;
}

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      close: false,
    };
  }

  shouldComponentUpdate() {
    const { close } = this.state;
    // Toggle close state
    if (close) {
      this.setState({ close: false });
    }
    return true;
  }

  // To stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    let id = window.setTimeout(() => null, 0);
    while (id) {
      id -= 1;
      window.clearTimeout(id);
    }
  }

  render() {
    const { classes, message, place, show, type, level, onClose } = this.props;
    const { close } = this.state;
    return (
      (type === 'snack' && (
        <Snackbar
          classes={{
            root: classnames(classes.snackbarRoot, classes[level]),
          }}
          place={place}
          color={level}
          message={message}
          open={show && !close}
          closeNotification={() => {
            this.setState({ close: true });
            if (onClose) {
              onClose();
            }
          }}
          close
        />
      )) ||
      (type === 'dialog' && (
        <Dialog
          classes={{
            root: `${classes.center} ${classes.modalRoot}`,
            paper: classes.modal,
          }}
          open={show && !close}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.setState({ close: true })}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
            <p>{message}</p>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => this.setState({ close: true })} color="danger" simple>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ))
    );
  }
}

Notification.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  place: PropTypes.string,
  show: PropTypes.bool,
  type: PropTypes.string,
  level: PropTypes.string,
  onClose: PropTypes.func,
};
Notification.defaultProps = {
  message: '',
  place: 'tc',
  show: false,
  type: 'snack',
  level: 'info',
  onClose: null,
};

export default withStyles(notificationsStyle)(Notification);
