import { mqFromDesktop } from '~/variables/breakpoints';

export default (theme) => ({
  container: {
    position: 'absolute',
    right: '20px',
    display: 'flex',
    [mqFromDesktop]: {
      position: 'relative',
      borderLeft: '1px solid #C4D0DD',
      paddingLeft: '25px',
    },
  },
  photo: {
    ...theme.defaultStyles?.boxShadow,
    padding: 0,
    transition: 'all 300ms linear',
    width: '24px',
    height: '24px',
    overflow: 'hidden',
    zIndex: '5',
    borderRadius: '50%',
    position: 'relative',
    top: '12px',
    [theme.breakpoints.up('sm')]: {
      top: '0px',
      width: '32px',
      height: '32px',
    },
    [theme.breakpoints.up('md')]: {
      top: '14px',
    },
  },
  avatarImg: {
    width: '100%',
    verticalAlign: 'middle',
    border: '0',
  },
  collapse: {
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
    background: '#FFFFFF',
    color: '#344051',
    position: 'absolute',
    top: '51px',
    left: '-60px',
    minWidth: '120px',
    [mqFromDesktop]: {
      top: '62px',
      left: '0',
      width: '150px',
    },
  },
  userItem: {
    padding: '7px 16px',
  },
  userLabel: {
    padding: '0 16px',
    color: theme.palette?.secondary.main,
  },
  link: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    width: '100%',
    display: 'block',
  },
  menuLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette?.primary.main,
    '&:hover': {
      color: theme.palette?.info.light,
    },
    '&:focus': {
      color: theme.palette?.info.light,
    },
  },
  dropdownLink: {
    color: theme.palette?.primary.main,
    '&:hover': {
      color: theme.palette?.info.light,
    },
    '&:focus': {
      color: theme.palette?.info.light,
    },
  },
  rightIcon: {
    color: theme.palette?.secondary.main,
    width: '18px',
    height: '18px',
    marginLeft: '-10px',
    '&.open': {
      transform: 'rotate(180deg)',
    },
  },
});
