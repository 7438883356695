import { mqFromDesktop, mqFromDesktopHD } from '~/variables/breakpoints';

export default {
  container: {
    margin: '20px 10px',
    [mqFromDesktop]: {
      margin: '10px auto',
      maxWidth: '95%',
    },
    '@media (min-width: 1600px)': {
      margin: '10px auto',
    },
    [mqFromDesktopHD]: {
      maxWidth: '1600px',
    },
  },
};
