export default {
  itemIcon: {
    color: 'inherit',
    width: '30px',
    float: 'left',
    position: 'inherit',
    marginRight: '15px',
    marginLeft: '5px',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '28px',
  },
  displayedIcon: {
    '&:hover': {
      display: 'none',
    },
  },
  hiddenIcon: {
    position: 'absolute',
    left: '20px',
    opacity: '0',
    '&:hover': {
      opacity: '1',
    },
  },
};
