import React from 'react';
import { desktopBreakpoint } from '~/variables/breakpoints';

export default function withWindowSize(Component) {
  return class withWindowSizes extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isMobile: false,
        innerHeight: 0,
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.setWindowParams);
      this.setWindowParams();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.setWindowParams);
    }

    setWindowParams = () => {
      const { innerHeight, innerWidth } = window;
      const isMobile = innerWidth < desktopBreakpoint;
      if (
        isMobile !== this.state.isMobile ||
        innerHeight !== this.state.innerHeight ||
        innerWidth !== this.state.innerWidth
      ) {
        this.setState({ isMobile, innerHeight, innerWidth });
      }
    };

    render() {
      const { state, props } = this;
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...state} {...props} />;
    }
  };
}
