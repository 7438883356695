import React from 'react';
import TabsBase from '@mui/material/Tabs';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';

const styles = (theme) => ({
  tabs: {
    padding: '0',
  },
  root: {
    minHeight: 'unset !important',
    backgroundColor: theme.palette?.primary.main,
  },
});

function Tabs({ classes, className, value, onChange, ...props }) {
  const defaultProps = {
    indicatorColor: 'primary',
    textColor: 'primary',
  };

  const mergedProps = { ...defaultProps, ...props };

  return (
    <TabsBase
      className={classnames(classes.tabs, className)}
      classes={{ root: classes.root }}
      value={value}
      onChange={onChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...mergedProps}
    />
  );
}

Tabs.propTypes = TabsBase.propTypes;

export default withStyles(styles)(Tabs);
