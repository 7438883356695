import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';

function LoadingProgress({ classes, loading }) {
  return (
    loading && (
      <div className={classes.wrapper}>
        <CircularProgress className={classes.icon} data-testid="loading-progress" />
      </div>
    )
  );
}

LoadingProgress.propTypes = {
  classes: PropTypes.instanceOf(Object),
  loading: PropTypes.bool.isRequired,
};
LoadingProgress.defaultProps = {
  classes: null,
};
export default withStyles({
  wrapper: { padding: '20px 0', margin: '0 auto' },
  icon: { display: 'block', margin: '0 auto' },
})(LoadingProgress);
