import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import { withStyles } from '@mui/styles';
import styles from './styles';

function StyledList({ classes, className, children, collapse = false, level }) {
  return (
    <List
      className={classnames(classes.list, classes[`level-${level}`], className, {
        [classes.collapse]: collapse,
      })}
    >
      {children}
    </List>
  );
}

StyledList.propTypes = {
  classes: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  children: PropTypes.node,
  collapse: PropTypes.bool,
  level: PropTypes.number,
};

StyledList.defaultProps = {
  classes: null,
  className: null,
  children: null,
  collapse: true,
  level: 0,
};

export default withStyles(styles)(StyledList);
