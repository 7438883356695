import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import styles from './styles';

function DashboardContainer({ classes, children, style }) {
  return (
    <div className={classes.container} style={style}>
      {children}
    </div>
  );
}

DashboardContainer.propTypes = {
  classes: PropTypes.instanceOf(Object),
  children: PropTypes.node.isRequired,
  style: PropTypes.instanceOf(Object),
};
DashboardContainer.defaultProps = {
  classes: null,
  style: null,
};

export default withStyles(styles)(DashboardContainer);
