// React icons
import AssignmentIcon from '@mui/icons-material/Assignment';
import React from 'react';

import { QueryCache, QueryClient } from '@tanstack/react-query';
import {
  json,
  LoaderFunctionArgs,
  Navigate,
  redirect,
  RouteObject,
  ShouldRevalidateFunction,
} from 'react-router-dom';
import AccountLayout from '~/layouts/Admin/Admin';
import ErrorPage from '~/views/Pages/ErrorPage';

import OnLoginClientsSelectorHandler from '~/components/Clients/OnLoginClientsSelector/OnLoginClientsSelector';
import HandleRedirects from '~/views/Dashboard/HandleRedirects';
import SessionHandler from '~/views/Dashboard/SessionHandler';
import App from './App';
import { DataService } from './services/DataService';

import utils from './layouts/Admin/Admin.utils';
import type { StaffPerm } from './layouts/Staff/StaffLayout';
import RoutingHandler from './RoutingHandler';
import { handleGetClientSessionInfo, SessionPlQueryReturnData } from './services/ClientServiceTS';
import { getAccountDetailsFromURL } from './utils/utils';
import ErrorBoundaryPage from './views/Pages/ErrorBoundaryPage';
import NoSessionPage from './views/Pages/NoSessionPage';
import JoeTest from './views/Staff/JoeTest';
import lazy from './lazy';
import { getSessionPlQueryKey } from './hooks/queries/useSessionPlQuery';

const RepricingVoiding = lazy(() => import('~/views/Staff/RepricingVoiding'));
const RepricingVoidingStatus = lazy(() => import('~/views/Staff/RepricingVoidingStatus'));

const EditingAdFormHandler = lazy(() => import('~/views/Dashboard/ad-form/editing-adform'));
const CreatingAdFormHandler = lazy(() => import('~/views/Dashboard/ad-form/creating-adform'));
const NewClient = lazy(() => import('~/views/Dashboard/NewClient'));
const AdManager = lazy(() => import('~/views/Dashboard/AdManager'));
const PubBidManager = lazy(() => import('~/views/Dashboard/PubBidManager'));
const Insights = lazy(() => import('~/views/Dashboard/Insights'));
const Billing = lazy(() => import('~/views/Dashboard/Billing/Billing'));
const InvoiceRoute = lazy(() => import('~/views/Dashboard/Billing/Invoice'));
const PublisherAds = lazy(() => import('~/views/Dashboard/PublisherAds'));
const PublisherPaymentHistory = lazy(() => import('~/views/Dashboard/PublisherPaymentHistory'));
const AccountUsers = lazy(() => import('~/views/Dashboard/Account/AccountUsers'));
const AccountSettings = lazy(() => import('~/views/Dashboard/Account/AccountSettings'));
const UserProfile = lazy(() => import('~/views/Dashboard/UserProfile'));
const ConversionPixel = lazy(() => import('~/views/Dashboard/ConversionPixel'));
const ArchivedAccountsPage = lazy(() => import('~/views/Pages/ArchivedAccountsPage'));
const Analytics = lazy(() => import('~/views/Dashboard/Analytics'));
const ForbiddenPage = lazy(() => import('~/views/Pages/ForbiddenPage'));
const OverviewHandler = lazy(() => import('~/views/Dashboard/Overview/OverviewHandler'));
const DeviceBidManager = lazy(() => import('~/views/Dashboard/DeviceBidManager'));
const AccountParameters = lazy(
  () => import('~/views/Dashboard/AccountParameters/AccountParameters'),
);
const AccountLevelRules = lazy(
  () => import('~/views/Dashboard/AccountParameters/AccountLevelRules'),
);
const StaffLayout = lazy(() => import('./layouts/Staff/StaffLayout'));
const InviteClient = lazy(() => import('./views/Staff/InviteClient/InviteClient'));
const AuthLayout = lazy(() => import('./layouts/Auth/Auth'));
const LoginPage = lazy(() => import('./views/Pages/LoginPage'));
const SetPassword = lazy(() => import('./views/Pages/SetPassword'));
const Invite = lazy(() => import('./views/Pages/Signup/Invite'));
const UserFormHandler = lazy(() => import('./views/Pages/Signup/UserForm'));
const SignUpCompletePage = lazy(() => import('./views/Pages/Signup/SignUpCompletePage'));
const StepLayout = lazy(() => import('./views/Pages/Signup/StepLayout'));
const AutomatedReporting = lazy(() => import('./views/Dashboard/AutomatedReporting'));
const AutoReportFormWrapper = lazy(
  () => import('./views/Dashboard/AutomatedReporting/AutoReportFormWrapper'),
);
const StaffDashboard = lazy(() => import('./views/Staff/StaffDashboard'));
const UploadSignupIOWrapper = lazy(() => import('./views/Staff/UploadSignupIO/UploadSignupIO'));
const Legal = lazy(() => import('./views/Legal/Legal'));
const LegalType = lazy(() => import('./views/Legal/LegalType'));
const ManageInvites = lazy(() => import('./views/Staff/ManageInvites/ManageInvites'));
const ClientDetails = lazy(() => import('./views/Pages/Signup/ClientForm'));
const BillingDetails = lazy(() => import('./views/Pages/Signup/BillingForm'));
const ReviewStep = lazy(() => import('./views/Pages/Signup/ReviewStep'));

// How to build routes:
//  path: path after /accountType/accountId
// name: to go in side bar if needed
// icon: icon to show in side bar
// perm: perm from perms object
// live: set to false to disable the route
// paramsToKeep: [] - an array of the query params to keep in the url across a given route (e.g. to/from/campaignId)
// collapse: set to true to show in the sidebar as a collapsable item
// views: if collapse, set to the routes of the collapse

// add perm to perms object below (e.g. { permName: permission})
//  permission: either
//    - an entry on "client/partner permissions"
//    - or "client/partner nav" fields from session.pl endpoint
//    - or client id (int)
//    - or object (can be nested) in same format as received from session.pl endpoint
//       e.g. perm: { publisher_manager: { bid: 'client' }}
//    -  you can include multiple options when applying permissions as an object, you will simply need to add a pipe between the options
//       e.g. perm: { device_manager: {bid: 'client|all' }}
//       the above means permissions 'client' OR 'all' will provide access to the corresponding route,
//       these options are manipulated in Admin.utils.js (refer to processPermissionValues function)
//    You can use an array for multiple AND checks

export const perms = {
  accountParameters: 'self_serve',
  accountRules: 'self_serve',
  accountSettings: 'dianomi',
  accountUsers: 'account',
  adForm: 'self_serve',
  adManager: 'self_serve',
  analytics: 'campaign',
  billing: 'billing',
  conversionPixel: 'account',
  deviceBidManagerClient: { device_manager: { bid: 'client|all' } },
  deviceBidManagerProduct: { device_manager: { bid: 'product|all' } },
  insights: 'insights',
  invoiceRoute: 'billing',
  isDianomi: 'dianomi',
  leads: 'myLeads',
  newClient: 'allow_create_client',
  overview: 'campaign',
  pubBidManagerClient: { publisher_manager: { bid: 'client|all' } },
  pubBidManagerProduct: { publisher_manager: { bid: 'product|all' } },
  publisherAds: 'ads',
  publisherPaymentHistory: 'campaign', // Once we have reworked our permissions handling, this needs to change to admin only
};

const sidebarRoutes = [
  {
    path: '/dashboard',
    name: 'Overview',
    icon: 'Overview',
    perm: perms.overview,
    live: true,
    paramsToKeep: ['from', 'to', 'period', 'campaign'],
  },
  {
    path: '/ad-manager',
    name: 'Ad Manager',
    icon: 'AdManager',
    perm: perms.adManager,
    live: true,
  },
  {
    collapse: true,
    path: 'new-ad/:type',
    name: 'New Ad',
    icon: 'NewAd',
    perm: perms.adForm,
    live: true,
    views: [
      {
        path: '/new-ad/native',
        name: 'New Native Ad',
        perm: perms.adForm,
        live: true,
      },
      {
        path: '/new-ad/video',
        name: 'New Video Ad',
        perm: perms.adForm,
        live: true,
      },
      {
        path: '/new-ad/display',
        name: 'New Display Ad',
        perm: perms.adForm,
        live: true,
      },
      {
        path: '/new-ad/podcast',
        name: 'New Podcast Ad',
        perm: perms.adForm,
        live: true,
      },
      // Removed for live testing - Archie
      // {
      //   path: '/new-ad/canvas',
      //   name: 'New Canvas Ad',
      //   perm: perms.adForm,
      //   live: true,
      // },
    ],
  },
  {
    path: '/my-leads',
    name: 'My Leads',
    icon: 'TickBox',
    paramsToKeep: ['from', 'to', 'period', 'campaign'],
    perm: perms.leads,
    live: true,
  },
  {
    collapse: true,
    path: '/analytics/:filter',
    name: 'Analytics',
    icon: 'Analytics',
    exact: true,
    state: 'analyticsCollapse',
    perm: perms.analytics,
    live: true,
    paramsToKeep: ['from', 'to', 'period', 'campaign'],
    viewsProp: 'analytics',
    views(analytics: unknown) {
      return DataService.analyticsNav(analytics);
    },
  },
  {
    collapse: true,
    path: '/insights/:insightPath',
    name: 'Insights',
    icon: 'Insights',
    state: 'insightsCollapse',
    perm: perms.insights,
    paramsToKeep: ['timeframe', 'country'],
    live: true,
    viewsProp: 'insightsNav',
    views(insightsNav: unknown) {
      return DataService.insightsNav(insightsNav);
    },
  },
  {
    path: '/billing',
    name: 'Billing',
    icon: 'Billing',
    perm: perms.billing,
    live: true,
  },
  {
    path: '/publisher-payment-history',
    name: 'Payment History',
    icon: 'CaseStudy',
    perm: perms.publisherPaymentHistory,
    accountType: 'partner',
    live: true,
  },
  {
    path: '/publisher-ads',
    name: 'Ads',
    icon: AssignmentIcon,
    perm: perms.publisherAds,
    live: true,
  },
  {
    collapse: true,
    path: '/account',
    name: 'Account',
    icon: 'Account',
    state: 'settingsCollapse',
    perm: perms.accountUsers,
    live: true,
    views: [
      {
        path: '/account/parameters',
        name: 'Bookings Manager',
        live: true,
        perm: perms.accountParameters,
      },
      {
        path: '/account/users',
        name: 'Users',
        perm: perms.accountUsers,
        live: true,
      },
      {
        path: '/account/profile',
        name: 'Settings',

        perm: perms.accountSettings,
        live: true,
      },
      { path: '/account/rules', name: 'Rules', live: true, perm: perms.accountRules },
      {
        path: '/account/publisher-manager/bid',
        name: 'Publisher Bidding',
        perm: perms.pubBidManagerClient,
        live: true,
      },
      {
        path: '/account/device-manager/bid',
        name: 'Device Bidding',
        perm: perms.deviceBidManagerClient,
        live: true,
      },
      {
        path: '/account/automated-reporting',
        name: 'Automated Reporting',
        perm: perms.analytics,
        live: true,
        accountType: 'client',
      },
      {
        path: '/account/actions',
        name: 'Conversion Pixel',
        perm: perms.conversionPixel,
        live: true,
      },
    ]
      .filter(Boolean)
      .sort((a, b) => a.name.localeCompare(b.name)),
  },
  {
    path: '/automated-reporting',
    name: 'Automated Reporting',
    perm: perms.analytics,
    live: true,
    accountType: 'partner',
    icon: 'AutomatedReporting',
  },
  {
    path: '/users',
    name: 'Users',
    perm: perms.analytics,
    accountType: 'partner',
    live: true,
    icon: 'Account',
  },
  {
    path: 'https://help.dianomi.com',
    name: 'Help Center',
    icon: 'CaseStudy',
    live: true,
    external: true,
    perm: '*',
  },
];
export default sidebarRoutes;

export type StaffSideBarRoute = {
  path: `/${string}`;
  name: string;
  live: boolean;
  perm: StaffPerm;
  allowed: boolean;
  icon?: string | null | undefined;
  collapse?: boolean;
  views?: StaffSideBarRoute[];
  state?: string;
};

const staffPerms = {
  client: 'client|admin',
  uploadIo: 'admin',
  joeTest: 'email:joe.harrison@dianomi.com',
  voiding: ['email:michael.stevens@dianomi.com', 'email:paul.hughes@dianomi.com'],
} as const;

export const staffSidebarRoutes: StaffSideBarRoute[] = [
  {
    path: '/home',
    name: 'Home',
    live: true,
    perm: '*',
    allowed: true,
    icon: 'Overview',
  },
  {
    path: '/select-account',
    live: true,
    perm: '*',
    allowed: true,
    name: 'Select Account',
    icon: 'CaseStudy',
  },
  {
    path: '/repricingvoiding',
    live: true,
    perm: staffPerms.voiding,
    allowed: true,
    name: 'CPM Fixer (test)',
    icon: 'CaseStudy',
  },
  {
    collapse: true,
    path: '/client',
    name: 'Client',
    icon: 'Account',
    state: 'settingsCollapse',
    perm: staffPerms.client,
    live: true,
    allowed: false,
    views: [
      {
        path: '/client/online-signup',
        collapse: true,
        live: true,
        perm: staffPerms.client,
        allowed: false,
        name: 'Online Signup',
        views: [
          {
            path: '/client/online-signup/invite-new-client',
            live: true,
            perm: staffPerms.client,
            allowed: false,
            name: 'Invite New Client',
          },
          {
            path: '/client/online-signup/manage-invites',
            live: true,
            perm: staffPerms.client,
            allowed: false,
            name: 'Manage Invites',
          },
          {
            path: '/client/online-signup/upload-io',
            live: true,
            perm: staffPerms.uploadIo,
            allowed: false,
            name: 'Upload IO',
          },
        ],
      },
    ],
  },
  {
    path: '/joe-test',
    name: 'Joe Test',
    live: true,
    perm: staffPerms.joeTest,
    allowed: true,
  },
];

/**
 * If you remove this anywhere in the routes array below,
 * you will need to handle permissions yourself
 * in your loader functions or components
 */
const defaultPermsLoader = (perm: (typeof perms)[keyof typeof perms], queryClient: QueryClient) => {
  return async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const { accountType, accountId } = getAccountDetailsFromURL(url.pathname) ?? {};
    const isPartner = accountType === 'partner';
    let newSessionData = {} as SessionPlQueryReturnData;
    const oldSessionData = queryClient.getQueryData<SessionPlQueryReturnData>(
      getSessionPlQueryKey({
        accountId,
        accountType,
      }),
    );
    const noSessionReferer =
      url.pathname !== '/no-session'
        ? new URLSearchParams({ referer: url.pathname }).toString()
        : '';

    const hasUrlAccountDetails = !!accountType && !!accountId;
    // when there's no url account details, we don't need to check if the session data is different to the url... obviously
    if (oldSessionData && !hasUrlAccountDetails) {
      newSessionData = oldSessionData;
    } else if (
      // ensure sessionData and url account details match
      oldSessionData &&
      oldSessionData.id.toString() === accountId &&
      oldSessionData.isPartner === isPartner
    ) {
      newSessionData = oldSessionData;
    } else {
      // either we don't have oldSessionData or url and account details don't match session data, so we need to get new session data
      try {
        queryClient.clear();
        newSessionData = await handleGetClientSessionInfo(null, { accountId, accountType });
        queryClient.setQueryData(
          getSessionPlQueryKey({
            accountId: String(newSessionData.id),
            accountType: newSessionData.accountType,
          }),
          newSessionData,
        );
      } catch (e) {
        console.error(e);
        return redirect(`/no-session?${noSessionReferer}`);
      }
    }

    if (!newSessionData) {
      return redirect(`/no-session?${noSessionReferer}`);
    }

    if (utils.checkRoutePermission(newSessionData, { perm })) {
      return json(newSessionData);
    }
    return redirect(`/${accountType}/${accountId}/forbidden`);
  };
};

const ignoreSearchParams: ShouldRevalidateFunction = ({ currentUrl, nextUrl }) => {
  const currentPathname = currentUrl.pathname;
  const nextPathname = nextUrl.pathname;
  return currentPathname !== nextPathname;
};

/**
 * moved this here as we will need to use it in the loader functions at some point
 *
 */
export const client = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error instanceof Error && error.cause instanceof Response && error.cause.status === 401) {
        window.location.replace('/no-session');
      }
    },
  }),
});

client.setQueryDefaults(['geoData', 'internalStore'], {
  cacheTime: Infinity,
  staleTime: Infinity,
});

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundaryPage />,
    children: [
      {
        index: true,
        element: <RoutingHandler />,
      },
      {
        element: <AccountLayout />,
        children: [
          {
            path: 'select-account',
            element: <OnLoginClientsSelectorHandler />,
          },
          {
            path: 'admin/*',
            element: <HandleRedirects />,
          },
          {
            path: ':accountType/:accountId',
            element: <SessionHandler />,
            children: [
              {
                index: true,
                element: <Navigate to="dashboard" />,
              },
              {
                path: 'dashboard',
                loader: defaultPermsLoader(perms.overview, client),
                element: <OverviewHandler />,
              },
              {
                path: 'account',
                loader: defaultPermsLoader(perms.accountUsers, client),
                shouldRevalidate: ignoreSearchParams,

                children: [
                  {
                    index: true,
                    element: <Navigate to="parameters" />,
                  },
                  {
                    path: 'parameters',
                    element: <AccountParameters />,
                    loader: defaultPermsLoader(perms.accountParameters, client),
                    shouldRevalidate: ignoreSearchParams,
                  },
                  {
                    path: 'rules',
                    element: <AccountLevelRules />,
                    loader: defaultPermsLoader(perms.accountRules, client),
                  },
                  {
                    path: 'publisher-manager/bid',
                    children: [
                      {
                        index: true,
                        element: <PubBidManager />,
                        loader: defaultPermsLoader(perms.pubBidManagerClient, client),
                      },
                      {
                        path: ':campaignId',
                        element: <PubBidManager />,
                        loader: defaultPermsLoader(perms.pubBidManagerProduct, client),
                      },
                    ],
                  },
                  {
                    path: 'device-manager/bid',
                    children: [
                      {
                        index: true,
                        element: <DeviceBidManager />,
                        loader: defaultPermsLoader(perms.deviceBidManagerClient, client),
                      },
                      {
                        path: ':campaignId',
                        element: <DeviceBidManager />,
                        loader: defaultPermsLoader(perms.deviceBidManagerProduct, client),
                      },
                    ],
                  },
                  {
                    path: 'actions',
                    element: <ConversionPixel />,
                    loader: defaultPermsLoader(perms.conversionPixel, client),
                  },
                  {
                    path: 'users',
                    element: <AccountUsers />,
                    loader: defaultPermsLoader(perms.accountUsers, client),
                  },
                  {
                    path: 'profile',
                    element: <AccountSettings />,
                    loader: defaultPermsLoader(perms.accountSettings, client),
                  },
                  {
                    path: 'automated-reporting',
                    loader: defaultPermsLoader(perms.analytics, client),
                    children: [
                      {
                        index: true,
                        element: <AutomatedReporting />,
                        loader: defaultPermsLoader(perms.analytics, client),
                      },
                      {
                        path: ':autoReportId',
                        element: <AutoReportFormWrapper />,
                        loader: defaultPermsLoader(perms.analytics, client),
                      },
                      {
                        path: 'new',
                        element: <AutoReportFormWrapper />,
                        loader: defaultPermsLoader(perms.analytics, client),
                      },
                    ],
                  },
                ],
              },
              // Routes specifically for partners which sit in a different position to clients
              {
                path: 'automated-reporting',
                loader: defaultPermsLoader(perms.analytics, client),
                children: [
                  {
                    index: true,
                    element: <AutomatedReporting />,
                    loader: defaultPermsLoader(perms.analytics, client),
                  },
                  {
                    path: ':autoReportId',
                    element: <AutoReportFormWrapper />,
                    loader: defaultPermsLoader(perms.analytics, client),
                  },
                  {
                    path: 'new',
                    element: <AutoReportFormWrapper />,
                    loader: defaultPermsLoader(perms.analytics, client),
                  },
                ],
              },
              {
                path: 'users',
                element: <AccountUsers />,
                loader: defaultPermsLoader(perms.analytics, client),
              },
              {
                path: 'ad-manager',
                children: [
                  {
                    index: true,
                    element: <AdManager />,
                    loader: defaultPermsLoader(perms.adManager, client),
                    shouldRevalidate: ignoreSearchParams,
                  },
                  {
                    path: ':campaignId',
                    children: [
                      {
                        path: 'edit',
                        element: <EditingAdFormHandler />,
                        loader: defaultPermsLoader(perms.adManager, client),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'new-ad',
                element: <Navigate to="native" />,
              },
              {
                path: 'new-ad/:type',
                element: <CreatingAdFormHandler />,
                loader: defaultPermsLoader(perms.adForm, client),
              },
              {
                path: 'my-leads',
                element: <Analytics />,
                loader: defaultPermsLoader(perms.leads, client),
              },
              {
                path: 'analytics/:filter',
                children: [
                  {
                    index: true,
                    element: <Analytics />,
                    loader: defaultPermsLoader(perms.analytics, client),
                  },
                  {
                    path: ':linkId',
                    element: <Analytics />,
                    loader: defaultPermsLoader(perms.analytics, client),
                  },
                ],
              },
              {
                path: 'insights/:insightPath',
                element: <Insights />,
                loader: defaultPermsLoader(perms.insights, client),
              },
              {
                path: 'billing',
                children: [
                  {
                    index: true,
                    element: <Billing />,
                    loader: defaultPermsLoader(perms.billing, client),
                  },
                  {
                    path: ':invoiceId',
                    element: <InvoiceRoute />,
                    loader: defaultPermsLoader(perms.invoiceRoute, client),
                  },
                ],
              },
              {
                path: 'publisher-payment-history',
                element: <PublisherPaymentHistory />,
                loader: defaultPermsLoader(perms.publisherPaymentHistory, client),
              },
              {
                path: 'publisher-ads',
                element: <PublisherAds />,
                loader: defaultPermsLoader(perms.publisherAds, client),
              },
              {
                path: 'new-client',
                element: <NewClient />,
                loader: defaultPermsLoader(perms.newClient, client),
              },
              {
                path: 'forbidden',
                element: <ForbiddenPage />,
              },
              {
                path: 'accounts-archived',
                element: <ArchivedAccountsPage />,
              },
              {
                path: '*',
                element: <ErrorPage />,
              },
            ],
          },
          {
            path: 'user-profile',
            element: <UserProfile />,
          },
          {
            path: '*',
            element: <ErrorPage />,
          },
        ],
      },
      {
        path: 'staff',
        element: <StaffLayout />,
        loader: defaultPermsLoader(perms.isDianomi, client),
        children: [
          { index: true, element: <Navigate to="home" /> },
          {
            path: 'home',
            element: <StaffDashboard />,
          },
          {
            path: 'repricingVoidingStatus/:jobId',
            element: <RepricingVoidingStatus />,
          },
          {
            path: 'repricingvoiding',
            element: <RepricingVoiding />,
          },
          {
            path: 'select-account',
            element: <OnLoginClientsSelectorHandler />,
          },
          {
            path: 'client',
            children: [
              {
                index: true,
                element: <Navigate to="online-signup" />,
              },
              {
                path: 'online-signup',
                children: [
                  { index: true, element: <Navigate to="invite" /> },
                  {
                    path: 'invite-new-client',
                    element: <InviteClient />,
                  },
                  {
                    path: 'manage-invites',
                    element: <ManageInvites />,
                  },
                  {
                    path: 'upload-io',
                    element: <UploadSignupIOWrapper />,
                  },
                ],
              },
            ],
          },
          {
            path: 'joe-test',
            element: <JoeTest />,
          },
          {
            path: '*',
            element: <ErrorPage />,
          },
        ],
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
      {
        element: <AuthLayout />,
        children: [
          { path: 'no-session', element: <NoSessionPage /> },

          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: 'forgot-password',
            element: <SetPassword />,
          },
          {
            path: 'set-password',
            element: <SetPassword />,
          },
          {
            path: '*',
            element: <ErrorPage />,
          },
          {
            path: 'legal',
            element: <Legal />,
            children: [
              {
                index: true,
                element: <Navigate to="marketing-terms/us" replace />,
              },
              {
                path: 'marketing-terms',
                element: <Navigate to="us" replace />,
              },
              {
                path: ':legalType',
                element: <LegalType />,
              },
              {
                path: ':legalType/:region',
                element: <LegalType />,
              },
            ],
          },
          {
            path: 'invite/',
            children: [
              {
                index: true,
                element: <Navigate to="invalid-invite" />,
              },
              {
                path: ':inviteId',
                element: <Invite />,
                children: [
                  {
                    path: 'step',
                    element: <StepLayout />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to="user" />,
                      },
                      {
                        path: 'user',
                        element: <UserFormHandler />,
                      },
                      {
                        path: 'company-details',
                        element: <ClientDetails />,
                      },
                      {
                        path: 'billing-details',
                        element: <BillingDetails />,
                      },
                      {
                        path: 'confirm',
                        element: <ReviewStep />,
                      },
                      {
                        path: '*',
                        element: <ErrorPage />,
                      },
                    ],
                  },
                  {
                    path: 'finish',
                    element: <SignUpCompletePage />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
];
