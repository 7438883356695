import { mqFromDesktop } from '~/variables/breakpoints';

const sidebarStyle = (theme) => {
  return {
    drawerPaperRTL: {
      [theme.breakpoints?.up('md')]: {
        left: 'auto !important',
        right: '0 !important',
      },
      [theme.breakpoints?.down('md')]: {
        left: '0  !important',
        right: 'auto !important',
      },
    },
    drawerPaper: {
      border: 'none',
      bottom: '0',
      left: '0',
      zIndex: '1032',
      transitionProperty: 'top, bottom, width',
      transitionDuration: '.2s, .2s, .35s',
      transitionTimingFunction: 'linear, linear, ease',
      width: theme.defaultStyles?.drawerWidth,
      [theme.breakpoints?.up('md')]: {
        width: theme.defaultStyles?.drawerWidth,
        height: '100%',
      },
      position: 'relative',
      '@media (max-width: 600px)': {
        width: '100%',
        ...theme.defaultStyles?.boxShadow,
        position: 'fixed',
        display: 'block',
        top: '50px',
        height: '100vh',
        right: '0',
        left: 'auto',
        zIndex: '1032',
        visibility: 'visible',
        overflowY: 'visible',
        borderTop: 'none',
        textAlign: 'left',
        paddingRight: '0px',
        paddingLeft: '0',
        transform: `translate3d(${theme.defaultStyles?.drawerWidth}px, 0, 0)`,
        transition: theme.defaultStyles?.transition,
      },
      '&:before,&:after': {
        position: 'absolute',
        zIndex: '3',
        width: '100%',
        height: '100%',
        content: '""',
        display: 'block',
        top: '0',
      },
    },
    blackBackground: {
      color: theme.palette?.secondary.main,
      background: theme.palette?.primary.main,
    },
    blueBackground: {
      color: theme.palette?.secondary.main,
      '&:after': {
        opacity: '.93',
      },
    },
    whiteBackground: {
      color: theme.palette?.grey[900],
      '&:after': {
        background: theme.palette?.secondary.main,
        opacity: '.93',
      },
    },
    whiteAfter: {
      '&:after': {
        backgroundColor: 'hsla(0,0%,71%,.3) !important',
      },
    },
    drawerPaperMini: {
      '@media (min-width: 960px)': {
        width: `${theme.defaultStyles?.drawerWidthMini}px!important`,
      },
      '@media (min-width: 600px) and (max-width: 960px)': {
        width: '0 !important',
      },
    },
    img: {
      width: '100%',
      verticalAlign: 'middle',
      border: '0',
      padding: '10px',
    },
    background: {
      position: 'absolute',
      zIndex: '1',
      height: '100%',
      width: '100%',
      display: 'block',
      top: '0',
      left: '0',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      transition: 'all 300ms linear',
    },
    collapseList: {
      marginTop: '0',
    },
    caretActive: {
      transform: 'rotate(180deg)',
    },
    sidebarWrapper: {
      position: 'fixed',
      height: 'calc(100vh - 75px)',
      overflow: 'auto',
      width: '100%',
      zIndex: '4',
      overflowScrolling: 'touch',
      transitionProperty: 'top, bottom, width',
      transitionDuration: '.2s, .2s, .35s',
      transitionTimingFunction: 'linear, linear, ease',
      color: 'inherit',
      paddingBottom: '30px',
      marginTop: '0',
      backgroundColor: theme.palette?.primary.main,
      [mqFromDesktop]: {
        maxWidth: '260px',
      },
      [theme.breakpoints?.down('sm')]: {
        height: '100%',
        width: '100%',
      },
    },
    mobileMenu: {
      '@media (max-width: 600px)': {
        display: 'none',
      },
    },
  };
};

export default sidebarStyle;
