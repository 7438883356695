import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ListItem from '@mui/material/ListItem';
import { useLocation } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import { withStyles } from '@mui/styles';
import SidebarItemIcon from '../ItemIcon';
import styles from './styles';
import { CustomNavLink } from '../../Link/Link';

/**
 *
 * @param {string[] | undefined} paramsToKeep
 * @param {string} searchString
 * @returns
 */
function keepParams(paramsToKeep, searchString) {
  if (!paramsToKeep || !searchString) return '';
  const searchParams = new URLSearchParams(searchString);
  const newSearchParams = new URLSearchParams();
  // add the params to keep, including duplicated ones, e,g, ?a=1&a=2
  paramsToKeep.forEach((param) => {
    searchParams.getAll(param).forEach((value) => {
      newSearchParams.append(param, value);
    });
  });
  return `?${newSearchParams.toString()}`;
}

const activeRoute = (routeName, level, hasChildren) => {
  if (level > 0 && !hasChildren) {
    const url = window.location.href.split('?');
    return url[0].endsWith(routeName) ? 'active' : '';
  }
  const x = window.location.href.indexOf(routeName) > -1 ? 'active' : '';
  return x;
};

function Link({
  classes,
  children,
  hasCaret,
  isCollapseActive = false,
  prop,
  sidebarOpen,
  onNavLinkClick,
  level = 0,
  basePath = '',
}) {
  const location = useLocation();
  const hasChildren = !!React.Children.count(children);
  const active = activeRoute(prop.path, level, hasChildren);
  const innerNavLinkClasses = cx(classes.collapseItemLink, {
    [classes.black]: active,
  });
  const navLinkClasses = cx(classes.itemLink, {
    [classes.black]: active,
  });
  const itemText = cx(classes.itemText, {
    [classes.itemTextMini]: !sidebarOpen,
  });
  const collapseItemText = cx(classes.collapseItemText, {
    [classes.collapseItemTextMini]: !sidebarOpen,
  });
  const caret = cx(classes.caret, classes[`caret-level-${level}`]);

  const [hover, setHover] = React.useState(isCollapseActive);
  return (
    <ListItem
      className={cx(
        {
          [classes.item]: !!prop.icon,
          [classes.collapseItem]: !!prop.icon,
        },
        classes.listItem,
      )}
    >
      {hasCaret ? (
        <div
          role="button"
          tabIndex={0}
          className={cx({
            [navLinkClasses]: !!prop.icon,
            [innerNavLinkClasses]: !prop.icon,
          })}
          onClick={(e) => onNavLinkClick && onNavLinkClick(e)}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <SidebarItemIcon prop={prop} active={active} hover={hover} />
          <ListItemText
            primary={prop.name}
            secondary={
              hasCaret && (
                <b className={`${caret} ${isCollapseActive ? classes.caretActive : ''}`} />
              )
            }
            disableTypography
            className={cx({
              [itemText]: !!prop.icon,
              [collapseItemText]: prop.icon === undefined,
              [classes.collapseItemTextActive]: active === 'active',
            })}
          />
        </div>
      ) : (
        <>
          {prop.external ? (
            <a
              href={prop.path}
              target="_blank"
              rel="noopener noreferrer"
              className={cx({
                [navLinkClasses]: !!prop.icon,
                [innerNavLinkClasses]: !prop.icon,
              })}
              onClick={(e) => onNavLinkClick && onNavLinkClick(e)}
              onMouseEnter={() => {
                setHover(true);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
            >
              <SidebarItemIcon prop={prop} active={active} hover={hover} />
              <ListItemText
                primary={prop.name}
                secondary={
                  hasCaret && (
                    <b className={`${caret} ${isCollapseActive ? classes.caretActive : ''}`} />
                  )
                }
                disableTypography
                className={cx({
                  [itemText]: !!prop.icon,
                  [collapseItemText]: prop.icon === undefined,
                  [classes.collapseItemTextActive]: active === 'active',
                })}
              />
            </a>
          ) : (
            <CustomNavLink
              to={`${basePath}${prop.path}${keepParams(prop.paramsToKeep, location.search)}`}
              className={cx({
                [navLinkClasses]: !!prop.icon,
                [innerNavLinkClasses]: !prop.icon,
              })}
              onClick={(e) => onNavLinkClick && onNavLinkClick(e)}
              onMouseEnter={() => {
                setHover(true);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
            >
              <SidebarItemIcon prop={prop} active={active} hover={hover} />
              <ListItemText
                primary={prop.name}
                secondary={
                  hasCaret && (
                    <b className={`${caret} ${isCollapseActive ? classes.caretActive : ''}`} />
                  )
                }
                disableTypography
                className={cx({
                  [itemText]: !!prop.icon,
                  [collapseItemText]: prop.icon === undefined,
                  [classes.collapseItemTextActive]: active === 'active',
                })}
              />
            </CustomNavLink>
          )}
        </>
      )}
      {children}
    </ListItem>
  );
}

Link.propTypes = {
  classes: PropTypes.instanceOf(Object),
  children: PropTypes.node,
  hasCaret: PropTypes.bool,
  isCollapseActive: PropTypes.bool,
  prop: PropTypes.instanceOf(Object),
  sidebarOpen: PropTypes.bool,
  onNavLinkClick: PropTypes.func.isRequired,
  level: PropTypes.number,
  basePath: PropTypes.string,
};
Link.defaultProps = {
  classes: null,
  children: null,
  hasCaret: false,
  isCollapseActive: false,
  prop: null,
  sidebarOpen: false,
  level: 0,
  basePath: '',
};

export default withStyles(styles)(Link);
