import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

const styles = {
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  root: {
    opacity: 1,
    animationName: 'fadeIn',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in',
    animationDuration: '.25s',
  },
};

function FadeIn({ classes, children }) {
  return <div className={classes.root}>{children}</div>;
}

FadeIn.propTypes = {
  classes: PropTypes.instanceOf(Object),
  children: PropTypes.node,
};
FadeIn.defaultProps = {
  classes: null,
  children: null,
};
export default withStyles(styles)(FadeIn);
