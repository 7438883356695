import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { DialogActions } from '@mui/material';

import Button from '~/components/Commons/Button';

import LoadingProgress from '../LoadingProgress';

function DialogButtons({
  classes,
  isSubmitting,
  handleDialogConfirm,
  acceptText,
  showCloseButton,
  handleDialogClose,
  cancelText,
}) {
  return (
    <DialogActions style={{ justifyContent: 'center', margin: '20px' }}>
      {isSubmitting ? (
        <LoadingProgress loading={isSubmitting} />
      ) : (
        <>
          {handleDialogConfirm && (
            <Button
              className={classnames(classes.button, classes.acceptButton)}
              onClick={handleDialogConfirm}
              color="primary"
              name={acceptText}
            >
              {acceptText}
            </Button>
          )}
          {showCloseButton && (
            <Button
              className={classes.button}
              onClick={handleDialogClose}
              color="primary"
              name={handleDialogConfirm ? 'Cancel' : 'Close'}
            >
              {cancelText || (handleDialogConfirm ? 'Cancel' : 'Close')}
            </Button>
          )}
        </>
      )}
    </DialogActions>
  );
}

DialogButtons.propTypes = {
  classes: PropTypes.instanceOf(Object),
  isSubmitting: PropTypes.bool,
  handleDialogClose: PropTypes.func,
  handleDialogConfirm: PropTypes.func,
  acceptText: PropTypes.string,
  showCloseButton: PropTypes.bool,
  cancelText: PropTypes.string,
};

DialogButtons.defaultProps = {
  classes: {},
  isSubmitting: false,
  handleDialogClose: null,
  handleDialogConfirm: null,
  acceptText: 'Accept',
  showCloseButton: true,
  cancelText: undefined,
};

export default DialogButtons;
