/* eslint-disable no-use-before-define */
import { useQuery } from '@tanstack/react-query';
import { customFetch } from '~/utils/customFetch';
import { ErrorObjectString, TruthyNumber } from '~/utils/typescriptHelpers';

type IdArray = string[];

export type StaffResponse = Prettify<{
  success: 1;
  data: StaffData;
}>;

type StaffResponseError = Prettify<
  {
    success: 0;
  } & ErrorObjectString
>;

type StaffData = Prettify<{
  permissions: StaffPermissions;
  name: string;
  phone: string | number | null;
  partners: IdArray | null;
  email: string;
  am_clients: IdArray | null;
  id: number;
}>;

type StaffPermissions = Prettify<{
  admin: TruthyNumber;
  jobs: TruthyNumber;
  partner: TruthyNumber;
  client: TruthyNumber;
  sales: TruthyNumber;
}>;

async function queryFn() {
  const { data } = await customFetch<Prettify<StaffResponse | StaffResponseError>>('/staff.pl');
  if (data.success === 0) {
    throw new Error(data.error);
  }
  return data.data;
}

export function useStaffQuery() {
  return useQuery({
    queryKey: ['staff'],
    queryFn,
    refetchOnWindowFocus: false,
  });
}
