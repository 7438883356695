import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// @mui/material components
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

const styles = {};
const useStyles = makeStyles(styles);

function GridContainer({ ...props }) {
  const classes = useStyles();
  const { children, direction, className, alignItems, justify, style, wrap } = props;
  return (
    <Grid
      container
      direction={direction}
      alignItems={alignItems}
      justifyContent={justify}
      style={style}
      wrap={wrap}
      className={classnames(classes.grid, className)}
    >
      {children}
    </Grid>
  );
}
GridContainer.propTypes = {
  style: PropTypes.instanceOf(Object),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  direction: PropTypes.string,
  alignItems: PropTypes.string,
  justify: PropTypes.string,
  wrap: PropTypes.string,
};
GridContainer.defaultProps = {
  style: null,
  className: '',
  direction: 'row',
  alignItems: 'stretch',
  justify: 'flex-start',
  wrap: 'wrap',
};

export default GridContainer;
