import { Theme } from '@mui/material';

const styles = (theme: Theme) =>
  ({
    button: {
      minWidth: '20px',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'none',
      padding: '10px',
      whiteSpace: 'nowrap',
      marginRight: '10px',
      '&[disabled]': {
        color: theme.palette?.grey[900],
      },
    },
    primary: {
      background: theme.palette?.primary.main,
      color: theme.palette?.secondary.main,
      '&:hover': {
        background: theme.palette?.info.light,
        color: theme.palette?.secondary.main,
      },
    },
    small: {
      padding: '8px 16px',
      margin: '0',
    },
    xsmall: {
      padding: '4px 12px',
    },
    secondary: {
      background: theme.palette?.secondary.main,
      color: theme.palette?.primary.main,
      '&:hover': {
        background: theme.palette?.info.light,
        color: theme.palette?.secondary.main,
      },
    },
    bordered: {
      background: 'transparent',
      color: theme.palette?.primary.main,
      border: `1px solid${theme.palette?.grey[300]}`,
      '&:hover': {
        background: theme.palette?.info.light,
        color: theme.palette?.secondary.main,
      },
    },
    wrap: {
      whiteSpace: 'normal',
    },
    isValid: {
      border: '2px solid',
      borderColor: theme.palette?.success.main,
    },
  }) as const;

export default styles;
