/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import defaultAvatar from '~/assets/img/placeholder.jpg';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Hidden from '@mui/material/Hidden';
import useSession from '~/hooks/useSession/useSession';
import classnames from 'classnames';

import ClientInfo from '~/context/ClientInfo';

import styles from './styles';
import SessionTimer from './SessionTimer';
import CustomLink from '../Link/Link';

const useStyles = makeStyles(styles);

function UserDropdownMenu() {
  const classes = useStyles();
  const { logout } = useSession();
  const [openAvatar, setOpenAvatar] = useState(false);
  const clientInfo = useContext(ClientInfo);
  const toggleRef = useRef();
  const location = useLocation();
  const { accountType, accountId } = useParams();

  const onLogoutClick = () => {
    setOpenAvatar(false);
    logout(500);
  };

  const myDetailsContent = (
    <CustomLink
      to={{ ...location, pathname: `/user-profile` }}
      title="My Details"
      className={classnames(classes.link, classes.dropdownLink)}
      onClick={() => setOpenAvatar(false)}
    >
      My Details
    </CustomLink>
  );
  const logoutContent = (
    <CustomLink
      to={location}
      className={classnames(classes.link, classes.dropdownLink)}
      title="Logout"
      onClick={onLogoutClick}
    >
      Logout
    </CustomLink>
  );

  const staffContent = (
    <CustomLink
      to={{ ...location, pathname: `/staff` }}
      title="Staff"
      className={classnames(classes.link, classes.dropdownLink)}
      onClick={() => setOpenAvatar(false)}
    >
      Staff
    </CustomLink>
  );

  return (
    <ClickAwayListener onClickAway={() => setOpenAvatar(false)}>
      <div className={classes.container}>
        <SessionTimer />
        <div
          className={classes.photo}
          onClick={() => setOpenAvatar(!openAvatar)}
          role="menuitem"
          tabIndex={0}
        >
          <img
            src={clientInfo?.login && clientInfo.login.logo ? clientInfo.login.logo : defaultAvatar}
            className={classes.avatarImg}
            alt={clientInfo?.login ? clientInfo.login.name : 'User profile'}
          />
        </div>
        <Hidden mdDown>
          <List>
            <ListItem className={classes.userItem}>
              <CustomLink
                to={`/${accountType}/${accountId}/user-profile`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenAvatar(!openAvatar);
                }}
                className={classnames(classes.link, classes.menuLink)}
                ref={toggleRef}
              >
                <ListItemText
                  primary={clientInfo?.login ? clientInfo.login.name : 'User profile'}
                  disableTypography
                  className={classes.userLabel}
                />
                <ExpandMoreIcon className={classnames(classes.rightIcon, { open: openAvatar })} />
              </CustomLink>
            </ListItem>
          </List>
        </Hidden>
        <Collapse in={openAvatar} unmountOnExit className={classes.collapse}>
          <List>
            {!!clientInfo?.login?.is_dianomi && !location.pathname.startsWith('/staff') && (
              <ListItem>
                <ListItemText primary={staffContent} disableTypography />
              </ListItem>
            )}
            <ListItem>
              <ListItemText primary={myDetailsContent} disableTypography />
            </ListItem>
            <ListItem>
              <ListItemText primary={logoutContent} disableTypography />
            </ListItem>
          </List>
        </Collapse>
      </div>
    </ClickAwayListener>
  );
}

UserDropdownMenu.propTypes = {};
UserDropdownMenu.defaultProps = {};

export default UserDropdownMenu;
