import { capitalize } from '@mui/material';
import { matchPath } from 'react-router-dom';
import { z } from 'zod';
import { DefaultImage } from '~/views/Dashboard/ad-form/S3ImageWidgetForm';
import { entries } from './typescriptHelpers';

export function hasOwnProp<TObj extends Record<string | number, unknown>>(
  obj: TObj,
  prop: string | number | symbol,
): prop is keyof TObj {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

/**
 * This is the theoretical start of all data on MyDianomi.
 * Never request data earlier than this!
 */
export const allTimeStart = new Date(2003, 0, 1);

const isDev = process.env.REACT_APP_ENVIRONMENT !== 'prod';
export const DOMAIN = process.env.CF_PAGES_URL as string;
export const API_BASE_PATH = `/${isDev ? 'preview/' : ''}${
  process.env.REACT_APP_API_BASE_PATH_2
}` as const;
export const SELFSERVE_URL = `${API_BASE_PATH}/selfserve` as const;

/**
 *
 * to be used in a .sort() function
 * Object must have a label key
 */
export const sortArrayOfObjectsByLabel = (
  a: Record<string, string | number>,
  b: Record<string, string | number>,
) => {
  if (a.label !== b.label) {
    if (a.label > b.label) {
      return 1;
    }
    return -1;
  }
  return 0;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertNullToUndefined<T extends Record<string, any>>(obj: T): T {
  const newObj = {} as T;

  if (Array.isArray(obj)) {
    return obj.map((item) => {
      if (item == null) {
        return null;
      }
      if (typeof item === 'object') {
        return convertNullToUndefined(item);
      }
      return item;
    }) as unknown as T;
  }
  for (const [key, value] of Object.entries(obj)) {
    const newValue = value === null ? undefined : value;
    newObj[key as keyof T] =
      newValue && typeof newValue === 'object' ? convertNullToUndefined(newValue) : newValue;
  }

  return newObj;
}

function accountTypeIsClientOrPartner(
  accountType?: string,
): accountType is 'client' | 'partner' | undefined {
  return ['client', 'partner', undefined].includes(accountType);
}

export function getAccountDetailsFromURL(path: string) {
  const match = matchPath('/:accountType/:accountId/*', path || '/');

  if (match) {
    const { accountType, accountId } = match.params;
    if (accountTypeIsClientOrPartner(accountType)) {
      return { accountType, accountId };
    }
  }
  return null;
}

export function getZodErrorMessageOrDefault(
  error: unknown,
  defaultMessage = 'Something went wrong',
) {
  if (error instanceof z.ZodError) {
    const errorMessage = entries(error.flatten().fieldErrors)
      ?.map(([key, value]) => `${capitalize(key.toString())}: ${value?.join(' ,')}`)
      .join('\n');

    return errorMessage;
  }
  return defaultMessage;
}

export function buildImageURL(url: string, width?: number, height?: number) {
  if (!url) return '';
  const fileType = url.split('.').pop();
  return `${process.env.REACT_APP_ENVIRONMENT !== 'prod' ? '/preview' : ''}/img/a/${url}${
    !width && !height ? '' : `/1/${width ?? ''}x${height ?? ''}.${fileType ?? 'png'}`
  }`;
}

export function buildCanvasImageURL(imageData: DefaultImage) {
  return `${
    process.env.REACT_APP_ENVIRONMENT !== 'prod' ? '/preview' : ''
  }/images/${imageData?.s3ObjectId}?width=${imageData?.width}&height=${imageData?.height}&fit=fill&type=${imageData?.type}`;
}

const currencies = ['$', 'AU$', 'S$', 'HK$', 'MYR', 'INR', 'US$', 'SGD', 'CA$', 'A$', '€']; // £ (pound) symbol added below

const escapeCurrencySymbol = (currency: string) =>
  currency
    .split('')
    .map((character) => (/^(\$)$/.test(character) ? `\\${character}` : character))
    .join('');
const createCurrencyTypesRegex = () => {
  let regex = `\\${String.fromCharCode(163)}|`; // £ (pound) symbol added using fromCharCode due to build method replacing it with ascii character
  for (let i = 0; i < currencies.length; i += 1) {
    regex += `${escapeCurrencySymbol(currencies[i])}${i + 1 < currencies.length ? '|' : ''}`;
  }
  return regex;
};
const isCurrencyRegexString = `^-*(${createCurrencyTypesRegex()})+([0-9\\.,-\\s])*$`;
const currencyRegexString = `(${createCurrencyTypesRegex()})\\s*`;
const isCurrencyRegex = new RegExp(isCurrencyRegexString);
const currencyRegex = new RegExp(currencyRegexString);
const hasCommaRegex = /,/;
export const isCurrencyValue = (value: string) => isCurrencyRegex.test(value);
export const hasCommas = (value: string) => hasCommaRegex.test(value);
export const filterOutCurrencies = (value: string) => value.replace(currencyRegex, '');
export const filterOutCommas = (value: string) => value.replaceAll(/,/g, '');

export const idGenerator = () => Math.random().toString(36).substr(2, 9); // unique up to 10,000ish rows

export function convertToMinTwoDecimalPlaces(value: string | number | null) {
  if (!value) return '0.00';
  const nextValue = typeof value === 'string' ? value : value.toString();
  if (nextValue.includes('.')) {
    const [main, decimal] = nextValue.split('.');
    if (decimal.length < 2) {
      return `${main}.${decimal.padEnd(2, '0')}`;
    }
  } else {
    return `${value}.00`;
  }
  return nextValue;
}
