import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { ClientsService } from '~/services/ClientsService';

const checkAllowance = (clientInfo, permission, childKey = null) => {
  // Recursive function only allow 2 levels of nested entries
  const navPermissions =
    childKey !== null ? clientInfo.nav_permissions[childKey] : clientInfo.nav_permissions;

  if (!navPermissions) {
    return false;
  }
  if (
    permission === '*' ||
    (Number.isInteger(permission) && clientInfo.client.id === permission) ||
    Object.prototype.hasOwnProperty.call(navPermissions, permission) ||
    (Object.prototype.hasOwnProperty.call(clientInfo.permissions, permission) &&
      Boolean(clientInfo.permissions[permission]))
  ) {
    return true;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key in navPermissions) {
    if (
      navPermissions[key] !== null &&
      typeof navPermissions[key] === 'object' &&
      !navPermissions[key].shortcode
    ) {
      // Recurse into children
      return checkAllowance(clientInfo, permission, key);
    }
  }
  return false;
};
export const isPlainObject = (obj) => obj && !Array.isArray(obj) && typeof obj === 'object';

const processPermissionValues = (clientInfoPermission, routePermission) => {
  if (routePermission.includes('|')) {
    const routePermissionArray = routePermission.split('|');
    const checkPermissionValues = routePermissionArray.some((permissionValue) => {
      return permissionValue === clientInfoPermission;
    });
    return checkPermissionValues;
  }
  return clientInfoPermission === routePermission;
};

const checkPermissionByObject = (routePermissionsObject, clientInfoPermissions) =>
  Object.entries(routePermissionsObject).every(([key, value]) => {
    // if value is an object, recurse
    if (clientInfoPermissions && clientInfoPermissions[key] && isPlainObject(value)) {
      return checkPermissionByObject(value, clientInfoPermissions[key]);
    }
    return (
      clientInfoPermissions &&
      clientInfoPermissions[key] &&
      processPermissionValues(clientInfoPermissions[key], value)
    );
  });

const checkRoutePermission = (clientInfo, route) => {
  const permission = [route.perm].flat(Infinity);
  // Bypass dashboard-splash page
  if (route.path === '/dashboard' && (clientInfo.isChild || clientInfo.isParent)) {
    return true;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key in permission) {
    if (
      isPlainObject(permission[key]) &&
      !checkPermissionByObject(permission[key], clientInfo.permissions)
    ) {
      return false;
    }
    // AND behavior. If one of them is not allowed we return false
    if (!isPlainObject(permission[key]) && !checkAllowance(clientInfo, permission[key])) {
      return false;
    }
  }
  return true;
};

const setRoutesPermissions = (processedRoutes, clientInfo) => {
  if (!clientInfo || clientInfo?.emptyAccounts) {
    return [];
  }

  if (!clientInfo.nav || !clientInfo.permissions) {
    Sentry.withScope((scope) => {
      scope.setTags({
        message: 'Error trying to setRoutePermissions',
        error: clientInfo?.error,
        file: 'admin.jsx',
        fn: 'setRoutePermissions()-01',
      });
      Sentry.captureException(new Error('setRoutesPermissions()'));
    });
    // return [];
  }
  return processedRoutes.map((route) => {
    const updatedRoute = { ...route };
    if (route.collapse && route.views) {
      let newViews = updatedRoute.views;
      if (typeof route.views === 'function') {
        if (route.viewsProp === 'analytics') {
          newViews = route.views(clientInfo.nav.analytics);
        } else if (route.viewsProp === 'insightsNav') {
          newViews = route.views(clientInfo.insights_nav);
        } else {
          // We are handling a callback function to get data dinamically
          newViews = route.views(clientInfo);
        }
      }
      updatedRoute.views = setRoutesPermissions(newViews, clientInfo);
    }
    updatedRoute.allowed = checkRoutePermission(clientInfo, updatedRoute);
    return updatedRoute;
  });
};

const getActiveRoute = (processedRoutes) => {
  const activeRoute = 'Dianomi';
  for (let i = 0; i < processedRoutes.length; i += 1) {
    if (processedRoutes[i].collapse) {
      const collapseActiveRoute = getActiveRoute(processedRoutes[i].views);
      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute;
      }
    } else if (
      window.location.href.indexOf(processedRoutes[i].path) !== -1 &&
      processedRoutes[i].name
    ) {
      return processedRoutes[i].name;
    }
  }

  return activeRoute;
};

const checkAccountAccess = (id, isPartner, clientInfo) => {
  // Check if account ID is included within login accounts
  let access = false;
  if (clientInfo.accounts && clientInfo.accounts.flatAccounts) {
    access = clientInfo.accounts.flatAccounts.some(
      (account) => account.id.toString() === id && account.isPartner === isPartner,
    );
  }
  return access;
};

const getSearchParams = (search) => {
  const params = {};
  if (search) {
    search
      .replace('?', '')
      .split('&')
      .forEach((param) => {
        const [key, value] = param.split('=');
        params[key] = value;
      });
  }
  return params;
};

const handleGetClientInfo = async () => {
  const clientData = await ClientsService.getClientInfo();
  if (!clientData || clientData.error) {
    Sentry.withScope((scope) => {
      scope.setTags({
        message: 'no clientData or clientData.error from getClientInfo()',
        file: 'Admin.jsx',
        error: clientData?.error,
        fn: 'handleGetClientInfo()-01',
      });
      Sentry.captureException(new Error('getAccounts()'));
    });
    return null;
  }
  clientData.isParent = !!clientData.children;
  clientData.isChild = clientData.parent_client_id !== null;
  return clientData;
};

const mapAnalyticRoutes = (analyticRoutes) => {
  const linkObject = {};
  Object.values(analyticRoutes).forEach((product) => {
    if (product.links) {
      Object.values(product.links).forEach((linkId) => {
        linkObject[`${product.name} ${linkId}`] = {
          name: `${product.name} ${linkId}`,
          linked: true,
          hidden_fields: 0,
          shortcode: linkId,
          periods: product.periods,
          takes_product: 0,
          visible_to_client: 0,
          visible_to_partner: 0,
          isLink: true,
        };
      });
    }
  });
  return { ...analyticRoutes, ...linkObject };
};

const handleGetGenieNav = async ({ id, isPartner, stats }) => {
  const genieNav = await ClientsService.getGenieNav(id, isPartner);
  if (genieNav && !genieNav.error) {
    const newStatsNav = {};
    Object.values(stats).forEach((shortcode) => {
      if (shortcode && genieNav[shortcode]) {
        newStatsNav[shortcode] = genieNav[shortcode];
      }
    });
    return mapAnalyticRoutes(newStatsNav);
  }
  if (genieNav && genieNav.error && genieNav.error === 'no permissions') {
    throw new Error('no permissions', { cause: genieNav });
  }
  return null;
};

const handleGetAccounts = async () => {
  const accountsData = await ClientsService.getAccounts();
  if (!accountsData || accountsData.error) {
    if (accountsData.error !== 'no valid session') {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'no accountsData or accountsData.error from getAccounts()',
          file: 'Admin.jsx',
          error: accountsData?.error,
          fn: 'handleGetAccounts()-01',
        });
        Sentry.captureException(new Error('getAccounts()'));
      });
    }
    return null;
  }
  const emptyAccounts =
    accountsData.length === 0 ||
    (accountsData.clients.length === 0 && accountsData.partners.length === 0);

  return { accounts: accountsData, emptyAccounts };
};

const getDefaultParams = (location) => {
  const query = new URLSearchParams(location.search);
  const fromDateParam = query.get('from');
  const toDateParam = query.get('to');
  const periodParam = query.get('period');
  const campaignParam = query.get('campaign');

  const thirtyDaysAgo = dayjs().subtract(1, 'month');
  const today = dayjs();

  return {
    from: fromDateParam || thirtyDaysAgo.format('YYYYMMDD'),
    to: toDateParam || today.format('YYYYMMDD'),
    currentPeriodFilter: periodParam || '',
    customDateSearch: !!fromDateParam || !!toDateParam || !!periodParam,
    campaign: campaignParam?.split(',') || ['all'],
  };
};

const buildSearchParams = ({ from, to, campaign, currentPeriodFilter, customDateSearch }) => {
  const searchParams = {};
  if (customDateSearch) {
    searchParams.to = to;
    searchParams.from = from;
    if (currentPeriodFilter) {
      searchParams.period = currentPeriodFilter;
    }
  }
  const [firstCampaign] = campaign;
  if (firstCampaign !== 'all') {
    searchParams.campaign = campaign.join(',');
  }
  return searchParams;
};

const utils = {
  getDefaultParams,
  checkAccountAccess,
  getActiveRoute,
  setRoutesPermissions,
  checkAllowance,
  getSearchParams,
  handleGetClientInfo,
  handleGetGenieNav,
  handleGetAccounts,
  buildSearchParams,
  checkRoutePermission,
};

export default utils;
