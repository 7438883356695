/* eslint-disable no-use-before-define */
import { CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import * as Sentry from '@sentry/react';
import { CookiesProvider } from 'react-cookie';
import { Outlet } from 'react-router-dom';
import LoadingProgress from '~/components/Commons/LoadingProgress';
import { NotificationsProvider } from '~/context/Notifications';
import useSessionPlQuery from '~/hooks/queries/useSessionPlQuery';
import UnderMaintenancePage from '~/views/Pages/UnderMaintenancePage';
import { ClientInfoProvider } from '~/context/ClientInfo/useClientInfo';
import { MenuProvider } from '~/context/useMiniMenu';
import GlobalNotification from '~/components/Commons/Notification/GlobalNotification';
import theme from '~/theme';
import globalStyles from '~/globalStyles';
import ErrorBoundaryPage from '~/views/Pages/ErrorBoundaryPage';
import { client } from './routes';
import GlobalDialog from './components/Commons/ActionConfirmDialog/GlobalDialog';

export default function App() {
  return (
    <QueryClientProvider client={client}>
      <CookiesProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />
            <NotificationsProvider>
              <Sentry.ErrorBoundary
                showDialog
                // eslint-disable-next-line react/jsx-props-no-spreading
                fallback={(fallbackProps) => <ErrorBoundaryPage {...fallbackProps} />}
              >
                <ClientInfoProvider>
                  <MenuProvider>
                    <LoadingHandler />
                  </MenuProvider>
                </ClientInfoProvider>
                <GlobalDialog />
                <GlobalNotification />
              </Sentry.ErrorBoundary>
            </NotificationsProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

function LoadingHandler() {
  const { isInitialLoading, data } = useSessionPlQuery();

  const isUnderMaintenance =
    process.env.REACT_APP_UNDER_MAINTENANCE === 'true' || data?.maintenance === 1;

  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        useErrorBoundary: (error, query) => {
          if (error instanceof Error && error.cause instanceof Response) {
            return (
              query?.options?.queryKey?.[0] === 'session.pl' && Number(error?.cause?.status) >= 500
            );
          }
          return false;
        },
      },
    });
  }, [queryClient]);

  if (isUnderMaintenance) {
    return (
      <React.Suspense fallback={<LoadingProgress loading />}>
        <UnderMaintenancePage />
      </React.Suspense>
    );
  }

  if (isInitialLoading) {
    return <LoadingProgress loading />;
  }
  return (
    <React.Suspense fallback={<LoadingProgress loading />}>
      <Outlet />
    </React.Suspense>
  );
}
