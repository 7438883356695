import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  pageHeaderSubtitle: {
    fontSize: '15px',
    color: theme.palette?.primary.light,
    fontWeight: '600',
    margin: 0,
    padding: '0px 10px',
  },
});

function PageHeaderSubtitle({ classes, children }) {
  return <h4 className={classes.pageHeaderSubtitle}>{children}</h4>;
}

PageHeaderSubtitle.propTypes = {
  classes: PropTypes.instanceOf(Object),
  children: PropTypes.node,
};
PageHeaderSubtitle.defaultProps = {
  classes: null,
  children: null,
};
export default withStyles(styles)(PageHeaderSubtitle);
