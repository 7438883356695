/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import MaterialReactTable, {
  MRT_PaginationState,
  MRT_TableInstance,
  MRT_TableState,
  MRT_Updater,
  MaterialReactTableProps,
} from 'material-react-table';
import TableCell from '~/views/Dashboard/Analytics/TableCell';
import { Box } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RecordStringAny = Record<string, any>;

type OmittedProps<TData extends RecordStringAny> = Omit<
  Partial<MRT_TableState<TData>>,
  'isLoading' | 'showAlertBanner' | 'showProgressBars' | 'density' | 'pagination'
>;

export interface CustomState<TData extends RecordStringAny> extends OmittedProps<TData> {
  isLoading?: boolean;
  isFetching?: boolean;
  isError?: boolean;
}

export type BaseDataTableProps<TData extends RecordStringAny> = Prettify<
  Omit<MaterialReactTableProps<TData>, 'state'>
>;

export default function DataTable<TData extends RecordStringAny>({
  data,
  columns,
  state,
  initialState,
  Actions = undefined,
  currency,
  formatNumbers = true,
  ...rest
}: Prettify<
  BaseDataTableProps<TData> & {
    state?: CustomState<TData>;
    Actions?: React.ReactNode;
    currency?: string;
    formatNumbers?: boolean;
  }
>) {
  const [pagination, setPagination] = React.useState(() => ({
    pageIndex: 0,
    pageSize: localStorage.getItem('tablePageSize')
      ? parseInt(localStorage.getItem('tablePageSize') ?? '100', 10)
      : 100,
  }));

  const tableRef = React.useRef<MRT_TableInstance<TData> | null>(null);

  const onPaginationChange = React.useCallback((updater: MRT_Updater<MRT_PaginationState>) => {
    setPagination((prevState) => {
      if (typeof updater === 'function') {
        const newState = updater(prevState);
        if (newState.pageSize !== prevState.pageSize) {
          localStorage.setItem('tablePageSize', newState.pageSize.toString());
        }
        return newState;
      }
      return updater;
    });
  }, []);

  return (
    <MaterialReactTable
      columns={columns}
      data={data} // data is undefined on first render
      enableStickyHeader
      enableTableFooter
      enableDensityToggle={false}
      enableColumnFilterModes
      enablePinning
      enableMultiSort
      tableInstanceRef={tableRef}
      enableFullScreenToggle={false}
      renderTopToolbarCustomActions={() => Actions}
      onPaginationChange={onPaginationChange}
      renderEmptyRowsFallback={({ table }) => {
        if (data.length === 0) {
          return <Box sx={{ p: 2, textAlign: 'center' }}>No data available</Box>;
        }
        if (table.getFilteredRowModel().rows.length === 0) {
          return <Box sx={{ p: 2, textAlign: 'center' }}>No data matches current filters.</Box>;
        }
        return <Box sx={{ p: 2, textAlign: 'center' }}>No data available</Box>;
      }}
      defaultColumn={{
        Cell: ({ renderedCellValue, column, row }) => {
          let columnId = column.id;
          if (columnId.toLowerCase().includes('custom')) {
            [columnId] = columnId.split('.');
          }

          return (
            <TableCell
              value={row.original[columnId] ?? renderedCellValue}
              column={{
                header: column.columnDef.header,
                accessor: column.columnDef.accessorKey as string,
              }}
              row={row.original}
              currency={currency ?? ''}
              formatNumbers={formatNumbers}
            />
          );
        },
      }}
      state={{
        ...state,
        isLoading: state?.isLoading || false,
        showAlertBanner: state?.isError || false,
        showProgressBars: state?.isFetching || false,
        pagination,
        density: 'compact',
      }}
      initialState={{
        sorting: data[0] && 'date' in data[0] ? [{ id: 'date', desc: true }] : [],
        ...initialState,
      }}
      {...rest}
    />
  );
}
