import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

const styles = {};

function GridItem({ ...props }) {
  const { classes, children, className, xs, sm, md, style } = props;
  return (
    <Grid
      item
      className={classnames(classes.grid, className)}
      xs={xs}
      sm={sm}
      md={md}
      style={style}
    >
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  classes: PropTypes.instanceOf(Object),
  style: PropTypes.instanceOf(Object),
  children: PropTypes.node,
  className: PropTypes.string,
  xs: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  sm: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
};
GridItem.defaultProps = {
  children: null,
  style: null,
  classes: null,
  className: '',
  xs: null,
  sm: null,
  md: null,
};

export default withStyles(styles)(GridItem);
