const adminNavbarLinksStyle = (theme) => ({
  pooperResponsive: {
    zIndex: '1200',
    [theme.breakpoints?.down('md')]: {
      zIndex: '1640',
      position: 'absolute',
      float: 'none',
      width: 'auto',
      marginTop: '0',
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
      color: 'black',
    },
  },
  navbarWrapper: {
    display: 'flex',
    position: 'relative',
    width: 'auto',
    zIndex: '4',
  },
  linkText: {
    zIndex: '4',

    fontSize: '11px',
    textTransform: 'none',
    color: 'white',
    '&:hover': {
      color: 'lightBlue',
    },
  },
  buttonLink: {
    [theme.breakpoints?.down('md')]: {
      display: 'flex',
      width: '52px',
      height: 'auto',
      '& svg': {
        width: '30px',
        height: '24px',
        marginRight: '19px',
        marginLeft: '3px',
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        width: '30px',
        fontSize: '24px',
        lineHeight: '30px',
        marginRight: '19px',
        marginLeft: '3px',
      },
    },
    '@media (max-width: 960px)': {
      marginRight: '20px',
    },
  },
  links: {
    width: '20px',
    height: '20px',
    zIndex: '4',
    [theme.breakpoints?.down('md')]: {
      display: 'block',
      width: '30px',
      height: '30px',
      color: 'inherit',
      opacity: '0.8',
      marginRight: '16px',
      marginLeft: '-5px',
    },
  },
  managerClasses: {
    [theme.breakpoints?.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: '25px',
      marginTop: '5px',
    },
  },
  markdown: {
    fontSize: '1em',

    maxHeight: '70vh',
    '@media screen and (min-height: 700px)': { maxHeight: '50vh' },
  },
  headerLinksSvg: {
    color: '#A7AAB6',
    width: '20px !important',
    height: '20px !important',
    marginLeft: '10px',
  },

  sessionsWrapper: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: '20px',
  },
  activeSessions: {
    color: 'white',
  },
  activeSessionsWarn: {
    color: 'red',
  },
  sessionTableWrapper: {
    maxWidth: '700px',
    textAlign: 'center',
    margin: '25px auto',
    border: '1px solid #ccc',
  },
  whatsNewNotification: {
    textAlign: 'center',
  },
  clickHere: {
    cursor: 'pointer',
    '&:hover': {
      color: 'darkblue',
    },
  },
});

export default adminNavbarLinksStyle;
