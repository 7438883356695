import React from 'react';

/**
 * @deprecated Use the `use****Query()`s instead, see src/context/ClientInfo/useClientInfo.jsx
 */
const ClientInfo = React.createContext({
  name: null,
  logo: null,
  client: { id: null },
  login: null,
  nav: null,
  permissions: null,
  nav_permissions: null,
  client_permissions: null,
  partner_permissions: null,
});

export default ClientInfo;
